import React from 'react';

const PreviousValue = ({ children, previousValue, show = false, right = false, left = false, wide = false, header = "Poprzednia wartość:<hr/>" }) => {

    const previousValueHtml = header + previousValue;
    return (
        <div className={"w-100 position-relative " + ((show && (![undefined, null].includes(previousValue))) ? "border border-danger rounded-3" : "")}>
            <div>
                {children}
                {(show && (![undefined, null].includes(previousValue))) && (
                    <div className='tooltip position-absolute' style={{ top: '0em', right: '0.2em' }}>
                        <span className="bi bi-clock-history text-danger fs-4 z-3" />
                        <span className={"tooltiptext" + (wide ? " tooltip-500" : "") + (left ? " tooltip-top-left" : "") + (right ? " tooltip-top-right" : "") + " fs-6"} dangerouslySetInnerHTML={{ __html: previousValueHtml }} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PreviousValue;
