import React, { useState, useEffect } from 'react';
import HelpTooltip from './HelpTooltip';
import ProducerAddress from './ProducerAddress';
import CountrySelector from './CountrySelector';
import { validateProductName, validateLettersDigits, validateLettersDigitsSpecChars, validateDigits, validateCountryCode } from '../../utils/Validators';
import ValidationInfo from './ValidationInfo';
import CommunicationSpot from './CommunicationSpot';
import PreviousValue from './PreviousValue';
import ReadonlyOverlay from './ReadonlyOverlay';
import ConfirmPopup from './ConfirmPopup';

const tooltips = [
    "Zwróć szczególną uwagę na nazwę, podany przez Ciebie opis powinien być zgodny z nazwą na fakturze. Pozwoli Ci to uniknąć ewentualnych problemów celnych.",
    "Uzupełnij w języku, w jakim ma się pojawić na świadectwie.",
    "Kody taryfy celnej znajdziesz m. in. pod adresem<br/><a href='https://ext-isztar4.mf.gov.pl/taryfa_celna/browseNomen.xhtml' target='_blank' class='text-warning'>https://ext-isztar4.mf.gov.pl/taryfa_celna/browseNomen.xhtml</a>"
];

function Product({ index, data, previousData = {}, showPreviousData = false, applicant, exportOnly, onDataChange, onRemove, onClone, commentVisible = true, registerCallback, viewAll = false, readOnly = false, handleValidate }) {
    if (!data) {
        data = {}
    }
    const {
        productName,
        quantity,
        unit,
        quantityDesc,
        tariffCode,
        originCountry,
        producer,
        exporter,
        boughtInEU,
        boughtOutsideEU,
        importClearanceFile
    } = data;

    const {
        prevProductName,
        prevQuantity,
        prevUnit,
        prevQuantityDesc,
        prevTariffCode,
        prevOriginCountry,
        prevProducer,
        prevExporter,
        prevBoughtInEU,
        prevBoughtOutsideEU,
        prevImportClearanceFile
    } = {
        prevProductName: previousData.productName,
        prevQuantity: previousData.quantity,
        prevUnit: previousData.unit,
        prevQuantityDesc: previousData.quantityDesc,
        prevTariffCode: previousData.tariffCode,
        prevOriginCountry: previousData.originCountry,
        prevProducer: previousData.producer,
        prevExporter: previousData.exporter,
        prevBoughtInEU: previousData.boughtInEU,
        prevBoughtOutsideEU: previousData.boughtOutsideEU,
        prevImportClearanceFile: previousData.importClearanceFile
    };

    const [validators, setValidators] = useState({ productName: "", quantitySummary: "", tariffCode: "", originCountry: "", producer: "", boughtInEU: "", address: "" });
    const [validateTrigger, setValidateTrigger] = useState(0);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleProductDataChange = (e) => {
        const { name, value } = e.target;
        if (e.target.type === "radio") {
            let rname = name.split("_")[0];
            if (rname === "exporter") {
                onDataChange("exporter", "true");
                onDataChange("producer", "false");
            }
            if (rname === "producer") {
                onDataChange("exporter", "false");
                onDataChange("producer", "true");
            }
            if (rname === "boughtInEU") {
                onDataChange("boughtInEU", "true");
                onDataChange("boughtOutsideEU", "false");
            }
            if (rname === "boughtOutsideEU") {
                onDataChange("boughtOutsideEU", "true");
                onDataChange("boughtInEU", "false");
            }
        }
        else
            onDataChange(name, value);

        validate(e);
    };

    const handleTariffCodeInput = (e) => {
        if (/^[0-9]*$/.test(e.target.value)) {
            handleProductDataChange(e);
        }
    };

    const validate = (e) => {
        const { name, value } = e.target;
        let newValidators = { ...validators };
        switch (name) {
            case "productName":
                newValidators.productName = validateProductName(value) ? "false" : "true";
                break;
            case "quantity":
                if ((unit.length === 0) || (value.length + unit.length + quantityDesc.length + 2) > 40)
                    newValidators.quantitySummary = "true";
                else
                    newValidators.quantitySummary = validateLettersDigits(value, 10) ? "false" : "true";
                break;
            case "unit":
                if ((quantity.length === 0) || (quantity.length + value.length + quantityDesc.length + 2) > 40)
                    newValidators.quantitySummary = "true";
                else
                    newValidators.quantitySummary = validateLettersDigits(value, 30) ? "false" : "true";
                break;
            case "quantityDesc":
                if (((quantity.length === 0) || (unit.length === 0)) || (quantity.length + unit.length + value.length + 2) > 40)
                    newValidators.quantitySummary = "true";
                else
                    newValidators.quantitySummary = validateLettersDigitsSpecChars(value, 40, false) ? "false" : "true";
                break;
            case "tariffCode":
                newValidators.tariffCode = validateDigits(value, 10) ? "false" : "true";
                break;
            case "originCountry":
                setValidateTrigger(validateTrigger + 1);
                newValidators.originCountry = validateCountryCode(value, true) ? "false" : "true";
                break;
            case "address":
                newValidators.address = value;
                break;
            default:
                break;
        }
        if (name.startsWith("bought")) {
            newValidators.boughtInEU = "false";
            newValidators.boughtOutsideEU = "false";
        }
        if (name.startsWith("producer") || name.startsWith("exporter")) {
            newValidators.producer = "false";
        }
        setValidators(newValidators);
    };

    useEffect(() => {
        let count = 0;
        for (const key in validators) {
            if (validators.hasOwnProperty(key) && ((validators[key] === "true") || validators[key] === true)) {
                count++;
            }
        }
        handleValidate("product_" + index, (count === 0) ? "false" : "true");
    }, [validators]);

    return (
        <div className='d-flex flex-column mt-1'>
            <ReadonlyOverlay readonly={readOnly}>
                <div>
                    <div>
                        <div className='d-flex align-items-center'>
                            <label className="form-label mb-0">Nazwa towaru *</label>
                            <HelpTooltip title={tooltips[0]} wide={true} />
                            <ValidationInfo title="Wstaw właściwą wartość" visible={validators.productName === "true" ? "true" : "false"} />
                            <div className='me-auto' />
                            <CommunicationSpot
                                commentId={"2-1-" + index} location="Eksportowany produkt / towar" isVisible={commentVisible} registerCallback={registerCallback}
                                viewAll={viewAll}
                            />
                            {!readOnly && (
                                <div>
                                    <button type="button" className="btn btn-outline-primary ms-1 w7 h2 tooltip" onClick={() => setConfirmDelete(true)} title='Usuń produkt / towar'>
                                        Usuń
                                        <span className="tooltiptext">Usuń produkt / towar</span>
                                    </button>
                                    <ConfirmPopup
                                        show={confirmDelete}
                                        title="Potwierdź usunięcie towaru"
                                        text="Potwierdź usunięcie towaru. Operacji nie można cofnąć."
                                        onConfirm={() => {onRemove(index); setConfirmDelete(false);}}
                                        onCancel={() => setConfirmDelete(false)}
                                    />
                                </div>
                            )}
                        </div>
                        <PreviousValue previousValue={prevProductName} show={showPreviousData && (prevProductName !== productName)} left={true} wide={true}>
                            <input
                                type="text"
                                className="form-control my-0 h2 rounded-3"
                                maxLength="350"
                                name="productName"
                                value={productName}
                                onChange={handleProductDataChange}
                                onBlur={validate}
                                placeholder="Nazwa towaru zgodnie z zapisem na fakturze eksportowej, znaki, numery seryjne itp. (max 350 znaków)"
                                required={true}
                                invalid={validators.productName}
                            />
                        </PreviousValue>
                    </div>

                    <div className='d-flex flex-column mt-1'>
                        <div className='d-flex flex-row'>
                            <div className='w9'>
                                <label className="form-label">Ilość *</label>
                                <PreviousValue previousValue={prevQuantity} show={showPreviousData && (prevQuantity !== quantity)} right={true}>
                                    <input
                                        type="text"
                                        className="form-control my-0 w9 h2 rounded-3"
                                        maxLength="10"
                                        name="quantity"
                                        value={quantity}
                                        onChange={handleProductDataChange}
                                        onBlur={validate}
                                        placeholder="Ilość"
                                        required={true}
                                        invalid={validators.quantitySummary === "true" ? "true" : "false"}
                                    />
                                </PreviousValue>
                            </div>

                            <div className='ms-1'>
                                <div className='d-flex align-items-center w17'>
                                    <label className="form-label">Jednostka *</label>
                                    <HelpTooltip title={tooltips[1]} wide={true} />
                                </div>
                                <PreviousValue previousValue={prevUnit} show={showPreviousData && (prevUnit !== unit)} right={true}>
                                    <input
                                        type="text"
                                        className="form-control my-0 w17 h2 rounded-3"
                                        maxLength="30"
                                        name="unit"
                                        value={unit}
                                        onChange={handleProductDataChange}
                                        onBlur={validate}
                                        placeholder="Wpisz"
                                        required={true}
                                        invalid={validators.quantitySummary === "true" ? "true" : "false"}
                                    />
                                </PreviousValue>
                            </div>

                            <div className='ms-1'>
                                <div className='d-flex align-items-center w19'>
                                    <label className="form-label">Opcjonalne opisowe określenie ilości</label>
                                    <ValidationInfo title='Wypełnienie pól <strong>"Ilość"</strong> i <strong>"Jednostka"</strong> jest obowiązkowe. Łączna długość tekstu z pól: <strong>"Ilość"</strong>, <strong>"Jednostka"</strong> i <strong>"Opcjonalne opisowe określenie ilości"</strong> nie może przekraczać 38 znaków.' visible={validators.quantitySummary === "true" ? "true" : "false"} wide={true} />
                                </div>
                                <PreviousValue previousValue={prevQuantityDesc} show={showPreviousData && (prevQuantityDesc !== quantityDesc)} right={true}>
                                    <input
                                        type="text"
                                        className="form-control my-0 w19 h2 rounded-3"
                                        maxLength="40"
                                        name="quantityDesc"
                                        value={quantityDesc}
                                        onChange={handleProductDataChange}
                                        onBlur={validate}
                                        placeholder="Opcjonalne opisowe określenie ilości"
                                        invalid={validators.quantitySummary === "true" ? "true" : "false"}
                                    />
                                </PreviousValue>
                            </div>
                        </div>

                        <div className='d-flex flex-row pb-2 mt-1 border-bottom'>
                            <div className='w9'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Kod taryfy celnej *</label>
                                    <HelpTooltip title={tooltips[2]} wide={true} />
                                    <ValidationInfo title="Kod taryfy celnej może zawierać maksymalnie 10 cyfr." visible={validators.tariffCode === "true" ? "true" : "false"} />
                                </div>
                                <PreviousValue previousValue={prevTariffCode} show={showPreviousData && (prevTariffCode !== tariffCode)} right={true}>
                                    <input
                                        type="text"
                                        className="form-control my-0 w9 h2 rounded-3"
                                        maxLength="10"
                                        name="tariffCode"
                                        value={tariffCode}
                                        onChange={handleTariffCodeInput}
                                        onBlur={validate}
                                        placeholder="Kod taryfy celnej"
                                        required={true}
                                        invalid={validators.tariffCode}
                                    />
                                </PreviousValue>
                            </div>

                            <div className='ms-1 w17'>
                                <PreviousValue previousValue={prevOriginCountry} show={showPreviousData && (prevOriginCountry !== originCountry)} right={true}>
                                    <CountrySelector
                                        name="originCountry"
                                        data={originCountry}
                                        onDataChange={handleProductDataChange}
                                        label="Kraj pochodzenia"
                                        required="true"
                                        validateTrigger={validateTrigger}
                                    />
                                </PreviousValue>
                            </div>
                        </div>

                        <div className='d-flex flex-column mt-1'>
                            <PreviousValue previousValue={[true, "true"].includes(prevProducer) ? "TAK" : "NIE"} show={showPreviousData && (prevProducer !== producer)} right={true}>
                                <div>
                                    <div className='mb-1 d-flex align-items-center'>
                                        <label className="form-label">Czy jesteś producentem tego produktu / towaru? *</label>
                                        <ValidationInfo title="Wypełnienie pola jest obowiązkowe." visible={(producer !== "true" && exporter !== "true") ? "true" : "false"} />
                                    </div>
                                </div>

                                <div className='d-flex flex-column'>
                                    <div className="form-check form-check-inline mb-1">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={"producer_" + index}
                                            id={"producer_" + index}
                                            checked={(exportOnly === "true") ? false : (producer === "true")}
                                            disabled={exportOnly === "true"}
                                            onChange={handleProductDataChange}
                                        />
                                        <label className="form-check-label" htmlFor={"producer_" + index}>TAK</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={"exporter_" + index}
                                            id={"exporter_" + index}
                                            checked={(exporter === "true")}
                                            onChange={handleProductDataChange}
                                        />
                                        <label className="form-check-label" htmlFor={"exporter_" + index}>NIE</label>
                                    </div>
                                </div>
                            </PreviousValue>
                        </div>

                        {((producer === "true") || (exporter === "true")) && (
                            <div className='d-flex flex-column mt-2'>
                                <div className='mb-1'>
                                    <strong>{(producer === "true") ? "Adres produkcji" : "Adres producenta"}</strong>
                                </div>
                                <ProducerAddress
                                    index={index}
                                    data={data}
                                    previousData={previousData}
                                    showPreviousData={showPreviousData}
                                    isProducer={data.producer === "true"}
                                    fromOutsideEU={data.boughtOutsideUE === "true"}
                                    applicant={applicant}
                                    onDataChange={handleProductDataChange}
                                    onValidate={validate}
                                    readOnly={readOnly}
                                />
                            </div>
                        )}
                    </div>

                    {!readOnly && (
                        <div className='d-flex flex-row mt-1 pt-1 border-top'>
                            <button type="button" className="btn btn-outline-primary ms-auto w7 h2 tooltip" onClick={onClone} title='Duplikuj produkt / towar'>Duplikuj<span className="tooltiptext">Duplikuj produkt / towar</span></button>
                        </div>
                    )}
                </div>
            </ReadonlyOverlay>
        </div>
    );
}

export default Product;
