import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { appendSignedApplication, getSignedApplication, createEmptyFileData, updateAttachments } from '../../services/Application';
import { commitStep } from '../../services/Process';
import FileUploadButton from './FileUploadButton';
import LoadingSpinner from './LoadingSpinner';
import { useAuth } from '../../context/AuthContext';
import FileUploadComponent from './FileUploadComponent';
import HelpTooltip from './HelpTooltip';

const SignatureDescriptionA = () => {
    return (
        <div className='d-flex flex-column'>
            <div className='d-flex flex-row mb-1'>
                <span>Samodzielnie przez przedsiębiorcę, pracownika lub członka organów eksportera</span>
                <HelpTooltip title={"Jeśli potrzebujesz zmienić sposób podpisania wniosku, napisz wiadomość do operatora."} wide={true} /><br />
            </div>
            <div className='fw-bold'>
                KRS - członek organów
            </div>
            <div className='ms-1 mb-1'>
                Jeżeli wniosek o wydanie świadectwa podpisuje osoba uwidoczniona w KRS, nie musisz dostarczać dodatkowych załączników. Sami sprawdzimy dane w rejestrze.
            </div>
            <div className='fw-bold'>
                CEIDG - przedsiębiorca
            </div>
            <div className='ms-1 mb-1'>
                Jeżeli wniosek o wydanie świadectwa podpisuje przedsiębiorca (osoba fizyczna prowadząca działalność gospodarczą), musimy zweryfikować jego dane.<br />
                Załącz „Zaświadczenie o wpisie do CEIDG z danymi niejawnymi”, które pobierzesz od ręki na swoim koncie przedsiębiorcy w serwisie biznes.gov.pl. Znajdziesz je w zakładce „Konto przedsiębiorcy”, po wybraniu opcji Moja firma -&gt; Pobierz zaświadczenie -&gt; <u>Zaświadczenie o wpisie do CEIDG z danymi niejawnymi.pdf</u>.
            </div>
            <div className='fw-bold'>
                INNY REJESTR
            </div>
            <div className='ms-1 mb-1'>
                Załącz zaświadczenie z właściwego dla Twojej działalności rejestru.
            </div>
            <div className='fw-bold'>
                PRACOWNIK
            </div>
            <div className='ms-1 mb-1'>
                Załącz:
                <ol>
                    <li>pełnomocnictwo</li>
                    <li>jedno z powyższych zaświadczeń, z których wynika uprawnienie udzielających pełnomocnictwa.</li>
                </ol>
            </div>
        </div>
    );
};

const SignatureDescriptionB = () => {
    return (
        <div className='d-flex flex-column'>
            <div className='d-flex flex-row mb-1'>
                <span>Osobę fizyczną będącą pełnomocnikiem eksportera</span>
                <HelpTooltip title={"Jeśli potrzebujesz zmienić sposób podpisania wniosku, napisz wiadomość do operatora."} wide={true} /><br />
            </div>
            <div>
                Załącz:
            </div>
            <div className='ms-1 mb-1'>
                <ol>
                    <li>pełnomocnictwo od eksportera dla osoby podpisującej wniosek</li>
                    <li>jedno z poniższych zaświadczeń, z których wynika uprawnienie osób udzielających pełnomocnictwa.</li>
                </ol>
            </div>
            <div className='fw-bold'>
                KRS
            </div>
            <div className='ms-1 mb-1'>
                Jeżeli pełnomocnictwo podpisała osoba uwidoczniona w KRS, nie musisz dostarczać dodatkowych załączników. Sami sprawdzimy dane w rejestrze.
            </div>
            <div className='fw-bold'>
                CEIDG - przedsiębiorca
            </div>
            <div className='ms-1 mb-1'>
                Jeżeli pełnomocnictwo podpisał przedsiębiorca (osoba fizyczna prowadząca działalność gospodarczą), musimy zweryfikować jego dane.<br />
                Załącz „Zaświadczenie o wpisie do CEIDG z danymi niejawnymi”, które można pobrać od ręki na koncie przedsiębiorcy w serwisie biznes.gov.pl. Znajdziesz je w zakładce „Konto przedsiębiorcy”, po wybraniu opcji Moja firma -&gt; Pobierz zaświadczenie -&gt; <u>Zaświadczenie o wpisie do CEIDG z danymi niejawnymi.pdf</u>.
            </div>
            <div className='fw-bold'>
                INNY REJESTR
            </div>
            <div className='ms-1 mb-1'>
                Załącz zaświadczenie z właściwego dla działalności rejestru.
            </div>
        </div>
    );
};

const SignatureDescriptionC = ({exporterName, agentName, agentFiles}) => {
    return (
        <div className='d-flex flex-column'>
            <div className='d-flex flex-row mb-1'>
                <span>Firmę (np. agencję celną), której Eksporter zlecił obsługę procesów celnych</span>
                <HelpTooltip title={"Jeśli potrzebujesz zmienić sposób podpisania wniosku, napisz wiadomość do operatora."} wide={true} />
                <br />
            </div>
            <div>
                We wniosku dodałeś dokumenty reprezentacji Eksportera:
            </div>
            <div className='ms-1 mb-1'>
                <div className='d-flex flex-column ms-1'>
                    <div className='fw-semibold'>{exporterName}</div>
                    <div>przez firmę Wnioskodawcy:</div>
                    <div className='fw-semibold'>{agentName}</div>
                    {agentFiles.map((file, index) => (
                        <div key={"AgentFile_" + index}>
                            <FileUploadButton
                                fileId={file.id}
                                fileName={file.name}
                                readOnly={true}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div>
                Załącz:
            </div>
            <div className='ms-1 mb-1'>
                <ol>
                    <li>pełnomocnictwo od firmy Wnioskodawcy dla osoby podpisującej wniosek</li>
                    <li>jedno z poniższych zaświadczeń, z których wynika uprawnienie udzielających pełnomocnictwa.</li>
                </ol>
            </div>
            <div className='fw-bold'>
                KRS
            </div>
            <div className='ms-1 mb-1'>
                Jeżeli pełnomocnictwo podpisała osoba uwidoczniona w KRS, nie musisz dostarczać dodatkowych załączników. Sami sprawdzimy dane w rejestrze.
            </div>
            <div className='fw-bold'>
                CEIDG - przedsiębiorca
            </div>
            <div className='ms-1 mb-1'>
                Jeżeli pełnomocnictwo podpisał przedsiębiorca (osoba fizyczna prowadząca działalność gospodarczą), musimy zweryfikować jego dane.<br />
                Załącz „Zaświadczenie o wpisie do CEIDG z danymi niejawnymi”, które można pobrać od ręki na koncie przedsiębiorcy w serwisie biznes.gov.pl. Znajdziesz je w zakładce „Konto przedsiębiorcy”, po wybraniu opcji Moja firma -&gt; Pobierz zaświadczenie -&gt; <u>Zaświadczenie o wpisie do CEIDG z danymi niejawnymi.pdf</u>.
            </div>
            <div className='fw-bold'>
                INNY REJESTR
            </div>
            <div className='ms-1 mb-1'>
                Załącz zaświadczenie z właściwego dla działalności rejestru.
            </div>
        </div>
    );
};
const CaseSignature = ({ status, id, signedApplication = null, displayMode = null, applicationNumber = "000000", attachments = [], signedBy = "none", exporterName, agentName, agentFiles = [] }) => {
    const [showSignatureDetails, setShowSignatureDetails] = useState(false);
    const [applicationDownloaded, setApplicationDownloaded] = useState(false);
    const [signatureInProgress, setSignatureInProgress] = useState(false);
    const [signatureFileId, setSignatureFileId] = useState(signedApplication.fileId);
    const [signatureFileName, setSignatureFileName] = useState(signedApplication.fileName);
    const [applicationAttachments, setApplicationAttachments] = useState(attachments);
    const { accessToken, userName } = useAuth();
    const [loading, setLoading] = useState(false);

    const debug = false;

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year} r.`;
    };

    const getStatusMessage = (status) => {
        switch (status) {
            case 'signed':
                return 'Wniosek podpisany';
            case 'pending':
                return 'Wniosek oczekuje na weryfikację podpisu';
            case 'readyToSign':
                if (signedApplication.date)
                    return 'Wniosek podpisany niepoprawnie';
                return 'Wniosek niepodpisany';
            case 'failed':
                return 'Podpis nie powiódł się';
            default:
                return 'Nieznany status';
        }
    };

    const handleSignedApplicationAdd = async (signedFileId, signedFileName) => {
        setSignatureInProgress(true);

        setSignatureFileId(signedFileId);
        setSignatureFileName(signedFileName);
        await appendSignedApplication(id, signedFileId, signedFileName, accessToken);
        //await performCommit();

        setSignatureInProgress(false);
    };

    const handleGetApplication = async () => {
        setLoading(true);
        await getSignedApplication(id, (applicationNumber + "_wniosek_CO_oryginalny.pdf"), accessToken);
        setApplicationDownloaded(!applicationDownloaded);
        setLoading(false);
    };

    const handleAttachmentChange = (fileId, fileName, fileDescription, fileType, index, newFile = true) => {
        const fileInfo = createEmptyFileData();
        fileInfo.id = fileId;
        fileInfo.name = fileName;
        fileInfo.description = fileDescription;

        setApplicationAttachments(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index] = fileInfo;
            if (newFile)
                updatedFiles.push(createEmptyFileData());
            updateAttachments(id, updatedFiles, accessToken);
            return updatedFiles;
        });
    };

    const handleFileRemove = (index) => {
        setApplicationAttachments(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles.splice(index, 1);
            updateAttachments(id, updatedFiles, accessToken);
            return updatedFiles;
        });
    };

    const performCommit = async () => {
        try {
            const result = await commitStep(id, userName, accessToken);
            window.location.reload();
        } catch (error) {
            console.error("Error committing step:", error);
        }
    };

    useEffect(() => {
        if (status !== 'signed') {
            setShowSignatureDetails(true);
        }
    }, [status]);

    if (displayMode === 'Shipping') {
        return null;
    }

    return (
        <div className='border-top pt-1 mt-1'>
            <div className='w-100 d-flex flex-row align-items-center'>
                <div>
                    <i className={`fs-1 bi bi-${status === 'signed' ? 'check-circle-fill text-success' : ((status === 'readyToSign') ? 'x-circle-fill text-danger' : 'question-circle-fill text-warning')}`} />
                </div>

                <div className='ms-1'>
                    <strong>{getStatusMessage(status)}</strong><br />
                    {(signedApplication.date) && (
                        <span className='text-black-50'>Podpisano {signedApplication.date ? formatDate(signedApplication.date.split(" ")[0]) : "-"}</span>
                    )}
                </div>

                <div className='ms-auto'>
                    <button className='btn btn-outline-primary ms-auto w11 h2' onClick={() => setShowSignatureDetails(!showSignatureDetails)}>{showSignatureDetails ? 'Ukryj' : 'Pokaż'} szczegóły podpisu</button>
                </div>
            </div>

            {showSignatureDetails && (
                <div>
                    {(status === 'readyToSign') && (
                        <div>
                            <div className="pt-1">
                                {signedApplication.date && (<strong>Podpis w Twoim wniosku wymaga poprawy.<br /></strong>)}
                                <div className='mt-1'>Wypełniając wniosek, zaznaczyłeś, że będzie on PODPISANY przez:</div>
                                <div className="pt-1">
                                    {signedBy === "independently" && (
                                        <SignatureDescriptionA />
                                        )}
                                    {signedBy === "byProxy" && (
                                        <SignatureDescriptionB />
                                    )}
                                    {signedBy === "byAgent" && (
                                        <SignatureDescriptionC exporterName={exporterName} agentName={agentName} agentFiles={agentFiles} />
                                    )}
                                </div>
                                Możesz teraz pobrać gotowy wniosek. <strong>Podpisz go elektronicznie</strong> zgodnie z reprezentacją i załaduj podpisany plik korzystając z przycisku “Dodaj podpisany wniosek”.<br /><br />
                                Akceptujemy następujące formy podpisów:<br /><br />
                                <ul>
                                    <li>
                                        <strong>Darmowy profil zaufany</strong> - poprzez rządową stronę: <a href="https://www.gov.pl/web/gov/podpisz-dokument-elektronicznie-wykorzystaj-podpis-zaufany" target='_blank'>https://www.gov.pl/web/gov/podpisz-dokument-elektronicznie-wykorzystaj-podpis-zaufany</a>
                                    </li>
                                    <li>
                                        <strong>Podpis kwalifikowany</strong> - jeśli wykupiłeś go u jednego z dostawców: <a href="https://www.biznes.gov.pl/pl/portal/0075" target='_blank'>https://www.biznes.gov.pl/pl/portal/0075</a>
                                    </li>
                                </ul>
                            </div>

                            <div className='w-100 d-flex flex-row mt-2'>
                                {loading ? (
                                    <div className="btn btn-outline-primary ms-auto my-0 h2 w11">
                                        <LoadingSpinner text='Pobieranie wniosku' />
                                    </div>
                                ) : (
                                    <button className="btn btn-outline-primary ms-auto my-0 h2 w11" type="button" onClick={handleGetApplication}>Pobierz wniosek</button>
                                )}
                                {!signatureInProgress ? (
                                    <FileUploadButton
                                        className={"btn " + (applicationDownloaded ? "btn-primary" : "btn-outline-primary") + " ms-1 my-0 h2 w11"}
                                        text={(!signatureFileId ? "Dodaj" : "Zmień") + " podpisany wniosek"}
                                        pdfOnly={true}
                                        onChange={handleSignedApplicationAdd}
                                    />
                                ) : (
                                    <div className="btn btn-primary ms-1 my-0 h2 w11" role="status">
                                        <LoadingSpinner text='Przetwarzanie' />
                                    </div>
                                )}
                            </div>
                        </div>)}

                    {((!signatureInProgress) && signatureFileId && signatureFileName) && (
                        <div className='d-flex flex-column'>
                            <div className='w-100 d-flex flex-row mt-1'>
                                <div className='w8 fw-bold'>Podpisany wniosek</div>
                                <FileUploadButton
                                    className={"btn " + (applicationDownloaded ? "btn-primary" : "btn-outline-primary") + " ms-1 my-0 h2 w11"}
                                    fileId={signatureFileId}
                                    fileName={(applicationNumber + "_wniosek_CO_podpisany.pdf")}
                                />
                            </div>

                            {(status !== 'readyToSign') && (
                                <div className='w-100 d-flex flex-row mt-1'>
                                    <div className='w8 fw-bold'>Reprezentacja</div>
                                    <div>
                                        {signedBy === "independently" ? "Samodzielnie przez przedsiębiorcę, pracownika lub członka organów eksportera" : signedBy === "byProxy" ? "Osobę fizyczną będącą pełnomocnikiem eksportera" : "Firma, której Eksporter zlecił obsługę procesów celnych"}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {signatureFileId && (
                        <div className='w-100 d-flex flex-column mt-1'>
                            <div className='d-flex flex-column'>
                                {applicationAttachments.map((attachment, index) => (
                                    <div key={"ApplicationAttachment_" + attachment.id} className='mt-1'>
                                        <FileUploadComponent
                                            index={index}
                                            fileId={attachment.id}
                                            fileName={attachment.name}
                                            fileDescription={attachment.description}
                                            fileType="ApplicationAttachment"
                                            onChange={handleAttachmentChange}
                                            onRemove={() => handleFileRemove(index)}
                                            buttonLabel={index === 0 ? "Dodaj plik" : "Dodaj kolejny plik"}
                                            readOnly={["signed", "pending"].includes(status) ? "true" : "false"}
                                            showReadOnly={false}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {status === "readyToSign" && (
                        <div className='w-100 d-flex flex-row mt-2'>
                            {signatureFileId ? (
                                <button className='btn btn-primary ms-auto my-0 h2 w11' onClick={performCommit}>Zatwierdź</button>
                            ) : (
                                <button className='btn btn-primary ms-auto my-0 h2 w11' disabled>Zatwierdź</button>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

CaseSignature.propTypes = {
    status: PropTypes.oneOf(['signed', 'pending', 'readyToSign', 'failed']).isRequired,
};

export default CaseSignature;