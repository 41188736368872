import React, { useState, useEffect } from 'react';

function FileProductsConnector({ index, data = [], productsList, fileType, onDataChange, readOnly = "false" }) {
    const [products] = useState(productsList.sort((a, b) => a.productName.localeCompare(b.productName)));
    const [productQuantities] = useState(productsList.map((product) => { return { productName: product.productName, quantity: product.quantity, unit: product.unit } }));
    const [connectedProducts, setConnectedProducts] = useState(data.sort((a, b) => a.productName.localeCompare(b.productName)));
    const [filter, setFilter] = useState("");
    const [showSelector, setShowSelector] = useState(false);

    const findProductQuantityAndUnit = (productName) => {
        const product = productQuantities.find((product) => product.productName === productName);
        return product ? `${product.quantity} ${product.unit}` : "";
    }

    if (showSelector === false) {
        return (
            <div className='w-100'>
                {connectedProducts.length > 0 ? (
                    <div className='d-flex flex-column'>
                        <span className='mb-1'>Powiązane towary:</span>
                        <ul>
                            {connectedProducts.map((product, index) => (
                                <li key={"connectedProduct_" + index}>
                                    {product.productName} - {findProductQuantityAndUnit(product.productName)}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div className=''>
                        Brak powiązanych towarów.
                    </div>
                )}
                {[false, "false"].includes(readOnly) && (
                    <button type="button" className="btn btn-outline-primary w21 mt-1" onClick={() => setShowSelector(true)}>
                        Wybierz towary, których dotyczy ten dokument
                    </button>
                )}
            </div>
        );
    }

    const selectAll = () => {
        setFilter("");
        setConnectedProducts(products);
        onDataChange(products, fileType, index);
    }

    const clearAll = () => {
        setFilter("");
        setConnectedProducts([]);
        onDataChange([], fileType, index);
    }

    return (
        <div className='w-100'>
            <div className='d-flex flex-row'>
                <input type="text" className="form-control rounded-end-0 h2 w23" placeholder="Filtruj towary" value={filter} onChange={(e) => setFilter(e.target.value)} />
                <button type="button" className="btn btn-outline-secondary border border-start-0 rounded-start-0 h2 tooltip text-dark" onClick={() => setFilter("")}>X<span className='tooltiptext'>Wyczyść filtr</span></button>
                <button type="button" className="btn btn-outline-primary w9 h2 ms-auto" onClick={() => selectAll()}>
                    Zaznacz wszystkie
                </button>
                <button type="button" className="btn btn-outline-primary w11 h2 ms-1" onClick={() => clearAll()}>
                    Wyczyść zaznaczenie
                </button>
            </div>
            {(((products.length > 0) && ([false, "false"].includes(readOnly))) ? (
                products.filter((product) => product.productName.toLowerCase().includes(filter.toLowerCase())).map((product, indx) => (
                    <div key={"product_" + indx} className='d-flex flex-row mt-1'>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={"product_" + indx}
                            value={product.productName}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setConnectedProducts(prevProducts => {
                                        return [...prevProducts, { productName: product.productName }];
                                    });
                                    onDataChange([...connectedProducts, { productName: product.productName }], fileType, index);
                                } else {
                                    setConnectedProducts(prevProducts => {
                                        return prevProducts.filter((prod) => prod.productName !== product.productName);
                                    });
                                    onDataChange(connectedProducts.filter((prod) => prod.productName !== product.productName), fileType, index);
                                }

                            }}
                            checked={connectedProducts.some((prod) => prod.productName === product.productName)}
                        />
                        <label className="form-check-label" htmlFor={"product_" + indx}>{product.productName}</label>
                    </div>
                ))
            ) : (
                <div className='d-flex flex-row mb-1'>
                    Towary, których dotyczy ten dokument
                </div>
            ))
            }
            <button type="button" className="btn btn-outline-primary rounded-2 w7 h2 mt-1" onClick={() => setShowSelector(false)}>
                Zatwierdź
            </button>
        </div>
    );
}

export default FileProductsConnector;
