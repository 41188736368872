/**
 * Represents the page for processing a specific application.
 * @module ProcessApplication
 * @returns {JSX.Element} The JSX element representing the process application page.
 */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ApplicationAdd from '../components/common/ApplicationAdd';
import ApplicationStep2 from '../components/common/ApplicationStep2';
import ApplicationStep3 from '../components/common/ApplicationStep3';
import ApplicationStep4 from '../components/common/ApplicationStep4';
import ApplicationStep5 from '../components/common/ApplicationStep5';
import ApplicationMetadata from '../components/common/ApplicationMetadata';
import OperatorsPanel from '../components/common/OperatorsPanel';
import Stepper from '../components/common/Stepper';
import Sidebar from '../components/layout/Sidebar';
import { getApplicationMetadata, getOpenCommentCounts } from '../services/Application';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { useAuth } from '../context/AuthContext';
import ConfirmPopup from '../components/common/ConfirmPopup';
import AccessControl from '../components/common/AccessControl';

/**
 * Represents the ProcessApplication component.
 * This component handles the process of an application, including its steps, status, and display mode.
 * @returns {JSX.Element} The ProcessApplication component.
 */
function ProcessApplication() {
    const { accessToken } = useAuth();
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [conversationId, setConversationId] = useState();
    const [conversationData, setConversationData] = useState();
    const [status, setStatus] = useState(1);
    const [trigger, setTrigger] = useState(0);
    const [callbackRegister, setCallbackRegister] = useState({});
    const [displayMode, setDisplayMode] = useState(1); // 0 = Application, 1 = Metadata
    const [warnings, setWarnings] = useState([0, 0, 0, 0, 0]);
    const [messages, setMessages] = useState([0, 0, 0, 0, 0]);
    const [loading, setLoading] = useState(true);
    const [showWarning, setShowWarning] = useState(false);
    const containerRef = useRef(null);
    const applicationStepTitles = [
        'WNIOSKODAWCA',
        'EKSPORTOWANY PRODUKT / TOWAR',
        'PROCES PRODUKCJI / ODBIORCA TOWARU',
        'ZAŁĄCZNIKI',
        'PODSUMOWANIE / OŚWIADCZENIA'];
    /**
     * Array of metadata step titles.
     * @type {string[]}
     */
    const metadataStepTitles = [
        "WYPEŁNIANY",
        "ROZPATRYWANY",
        "OPŁATA I PODPIS",
        "W AKCEPTACJI",
        "ŚWIADECTWO WYSTAWIONE"];
    const metadataStepTitlesCancelled = [
        "WYPEŁNIANY",
        "ROZPATRYWANY",
        "OPŁATA I PODPIS",
        "W AKCEPTACJI",
        "SPRAWA ANULOWANA"];
    const statusToModeMap = {
        1: 0,
        2: 1,
        3: 1,
        4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1
    };
    const statusToActiveStepMap = {
        1: 1,
        2: 1,
        3: 2,
        4: 1,
        5: 3,
        6: 3,
        7: 2,
        8: 5,
        9: 3,
        10: 3,
        11: 3,
        12: 3,
        13: 4,
        14: 4,
        15: 5,
        16: 5,
        17: 5,
        18: 5
    };
    let { step, id } = useParams();
    const debug = false;

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSidebarVisible(true);
        } else {
            setIsSidebarVisible(false);
        }
    };

    const handleDisplayModeChange = (mode) => {
        setDisplayMode(mode);
    }

    const reloadMessagesCount = async () => {
        const msgCounts = await getOpenCommentCounts(id, accessToken);
        await setMessages(msgCounts);
    }

    const registerCallback = (callbackId, callback) => {
        setCallbackRegister(prevState => ({
            ...prevState,
            [callbackId]: (newStatus) => {
                const update = async (newStatus) => {
                    await callback(newStatus);

                    if (newStatus === "open")
                        reloadMessagesCount();
                    if (newStatus === "solved")
                        reloadMessagesCount();
                };

                update(newStatus);
            }
        }));
    };

    const setAllCommunicationSpotsInactive = () => {
        Object.values(callbackRegister).forEach(callback => {
            callback("inactive");
        });
    };

    const setWarningCount = async (index, count) => {
        if (debug) console.log("Warning count [" + index + "]: " + count);
        setWarnings(prevWarnings => {
            const newWarnings = [...prevWarnings];
            newWarnings[index] = count;
            return newWarnings;
        });
    };

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo(0, 0);
        }
    }, [step]);

    useEffect(() => {
        if (debug) console.log("trigger changed [" + trigger + "]");
    }, [trigger])

    useEffect(() => {
        if (debug) console.log("displayMode changed [" + displayMode + "]");
    }, [displayMode])

    useEffect(() => {
        if (debug) console.log("warnings changed [" + warnings[0] + "]");
    }, [warnings])

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            if (id === "00000000-0000-0000-0000-000000000000") {
                setDisplayMode(0);
                setLoading(false);
                return;
            }

            try {
                const metadata = await getApplicationMetadata(id, accessToken);
                if (metadata) {
                    setStatus(metadata.status);
                    setDisplayMode(statusToModeMap[metadata.status]);
                }

                const msgCounts = await getOpenCommentCounts(id, accessToken);
                setMessages(msgCounts);
            } catch (error) {
                console.error('Failed to fetch application metadata:', error);
            }
        };

        fetchData();
        setLoading(false);

        window.addEventListener('resize', handleResize);
        //handleResize(); // Wywołanie przy montowaniu komponentu

        return () => window.removeEventListener('resize', handleResize); // Oczyszczenie przy demontowaniu
    }, []);

    useEffect(() => {
        let msgCounts = [0, 0, 0, 0, 0];
        const fetchData = async () => {
            msgCounts = await getOpenCommentCounts(id, accessToken);
            setMessages(msgCounts);
            if (debug) console.log("Messages count - step: " + msgCounts);
        };

        fetchData();
    }, [step]);

    if (loading) {
        return <div className='w-100 p-4'><LoadingSpinner /></div>;
    }

    if (displayMode === 0)
        return (
            <main className="d-flex flex-row vh-100">
                <Sidebar />
                <AccessControl>
                    <div className='d-flex flex-column w-100'>
                        <Stepper
                            activeStep={step}
                            warnings={warnings}
                            messages={messages}
                            id={id}
                            titles={displayMode === 0 ? applicationStepTitles : (status === 8 ? metadataStepTitlesCancelled : metadataStepTitles)}
                            applicationMode={displayMode === 0}
                        />
                        <div className="d-flex flex-row content p-0 w-100" ref={containerRef}>
                            <div className="position-relative w-100">
                                <OperatorsPanel
                                    isVisible={status !== 1}
                                    modeChangeHandler={() => setShowWarning(true)}
                                />

                                {(step === '1') && <ApplicationAdd registerCallback={registerCallback} warningsCallback={setWarningCount} />}
                                {(step === '2') && <ApplicationStep2 registerCallback={registerCallback} warningsCallback={setWarningCount} />}
                                {(step === '3') && <ApplicationStep3 registerCallback={registerCallback} warningsCallback={setWarningCount} />}
                                {(step === '4') && <ApplicationStep4 registerCallback={registerCallback} warningsCallback={setWarningCount} />}
                                {(step === '5') && <ApplicationStep5 registerCallback={registerCallback} warningsCallback={setWarningCount} />}

                                {(status !== 1) && (
                                    <div className="d-flex flex-row w-100 py-2 border-top border-1">
                                        <button className="btn btn-outline-primary w11 ms-4 me-auto" onClick={() => setShowWarning(true)}>Przejdź do widoku sprawy</button>
                                    </div>
                                )}

                                <ConfirmPopup
                                    show={showWarning}
                                    title="Uwaga"
                                    text="Czy na pewno chcesz opuścić widok edycji wniosku? Niezapisane zmiany zostaną utracone."
                                    confirmText="Tak"
                                    cancelText="Nie"
                                    onConfirm={() => { handleDisplayModeChange(1); setShowWarning(false); }}
                                    onCancel={() => setShowWarning(false)}
                                />
                            </div>

                        </div>
                    </div>
                </AccessControl>
            </main>
        );

    if (displayMode === 1)
        return (
            <main className="d-flex flex-row" style={{ height: '100vh' }}>
                <Sidebar />
                <AccessControl>
                    <div className='d-flex flex-column w-100'>
                        <Stepper
                            activeStep={statusToActiveStepMap[status]}
                            id={id}
                            titles={displayMode === 0 ? applicationStepTitles : (status === 8 ? metadataStepTitlesCancelled : metadataStepTitles)}
                            applicationMode={displayMode === 0}
                        />
                        <div className="d-flex flex-row content p-0 w-100">
                            <div className='pb-4'>
                                <ApplicationMetadata
                                    displayModeHandler={handleDisplayModeChange}
                                />
                            </div>
                        </div>
                    </div>
                </AccessControl>
            </main>
        );
}

export default ProcessApplication;
