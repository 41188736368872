import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';

function CommunicationMessage({ author, text, createDate, readDate, authorRole, key }) {

    const [detailsVisible, setDetailsVisible] = useState(false);

    const toggleDetails = () => {
        setDetailsVisible(!detailsVisible);
    };

    return (
        <div className={"d-flex flex-column message mb-1 " + (authorRole === "operator" ? "ms-2 message-dark" : "me-2")} style={{padding: "4px"}} onClick={() => toggleDetails()}>
            <div className='d-flex flex-row'>
                {detailsVisible && (
                    <div className='d-flex flex-column mb-1 w-100 border-bottom'>
                        <div className='d-flex flex-row me-auto pb-1 w-100'>
                            <div className='small'>
                                <strong>{author}</strong>
                            </div>

                            {authorRole === "operator" && (
                                <div className='ps-1 very-small'>
                                    (operator)
                                </div>)}
                        </div>

                        <div className='d-flex flex-column'>
                            <div className='very-small'>
                                <i className='bi-clock'></i> Data dodania: <strong>{createDate}</strong>
                            </div>
                            <div className='very-small'>
                                <i className='bi-clock-fill'></i> Data odczytania: <strong>{(readDate ? readDate : "nie odczytano")}</strong>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div>
                {text.split("\n").map(line => (
                    <div>
                    {line}
                    <br />
                    </div>
                ))}
            </div>

            {!detailsVisible && (
            <div className='very-small d-flex flex-row'>
                <div className='ms-auto' style={authorRole === "operator" ? {color: "white"} : {color: "black"}}>
                    {author}&nbsp;
                </div>

                {readDate ? (
                    <div>
                        <i className='bi-clock-fill' title={"Data dodania: " + createDate + "\nData odczytania: " + readDate}></i>
                    </div>
                ) : (
                    <div>
                        <i className='bi-clock' title={"Data dodania: " + createDate}></i>
                    </div>
                )
                }
            </div>
            )}
        </div>
    );
}

export default CommunicationMessage;


