import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { selfActivateUser } from '../../services/User';
import LoadingSpinner from './LoadingSpinner';

function UserActivation() {
	let { userId } = useParams();
	const navigate = useNavigate();
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		const activate = async () => {
			setLoading(true);
			let result = await selfActivateUser(userId);
			setSuccess(result);
			setLoading(false);
		}

		if (userId)
			activate();
	}, []);


	return (
		<div className="row justify-content-center justify-content-md-start">
			<div className="col-12 col-md-8 col-lg-6 col-xl-5 my-5" style={{ width: '40%' }}>
				<span className="d-flex flex-column align-items-center mb-4">
					<img src="/assets/images/kig-e7Ti-vgY.png" className="mx-auto" alt="..." />
					<span className="text-white" style={{ fontSize: '0.75rem' }}>KRAJOWA IZBA GOSPODARCZA</span>
				</span>
				<div className="py-4 px-4 px-xl-5 bg-white rounded-4 w-100">
					<h1 className="display-4 text-center mb-1">Aktywacja konta</h1>
					<p className="text-muted text-center mb-2">Elektroniczne świadectwo pochodzenia</p>
					{loading ? (
						<div className='d-flex flex-column w-100'>
							<div className='mb-5'>
								<LoadingSpinner text="Proszę czekać, trwa aktywacja konta." />
							</div>
						</div>
					) : (
						success ? (
							<div className='d-flex flex-column w-100'>
								<div className='mb-2'>
									Aktywacja konta powiodła się.<br />
									Możesz już zalogować się do systemu.<br />
								</div>

								<div className='d-flex flex-row'>
									<button className="btn btn-primary rounded-2 ms-auto w7 h2" onClick={() => navigate("/Login", { replace: true })}>
										Zaloguj
									</button>
								</div>
							</div>
						) : (
							<div className='d-flex flex-column w-100'>
								<div className='mb-5'>
									W trakcie aktywacji wystąpił błąd.<br />
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
}

export default UserActivation;