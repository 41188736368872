import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SessionWarning = ({ show = false, initialSeconds, onExtendSession, onTimeout }) => {
    const [seconds, setSeconds] = useState(initialSeconds);
    const [showState, setShow] = useState(show);

    const handleExtendSession = () => {
        onExtendSession();
        setShow(false);
    };

    useEffect(() => {
        if (showState) {
            if (seconds > 0) {
                const timer = setInterval(() => {
                    setSeconds(seconds - 1);
                }, 1000);
                return () => clearInterval(timer);
            } else {
                setShow(false);
                onTimeout();
            }
        }
    }, [seconds, showState, onTimeout]);
    
    useEffect(() => {
        setShow(show);
        setSeconds(initialSeconds);
    }, [show, initialSeconds]);

    if (showState) return (
        <>
            <div className="modal-backdrop show z-1"></div>
            <div className="modal fade show z-3" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <i className='bi bi-exclamation-triangle-fill text-danger me-1 fs-2' />
                            <h3 className='small-caps me-auto'>KONIEC SESJI</h3>
                        </div>
                        <div className="modal-body text-dark px-2 py-1">
                            <p>Twoja sesja wygaśnie automatycznie za <strong>{seconds}</strong> sekund{([2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54].includes(seconds) ? "y" : (seconds === 1 ? "ę" : ""))}.</p>
                            <p>Jeśli chcesz kontynuować pracę, kliknij przycisk poniżej.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary w11" data-bs-dismiss="modal" onClick={handleExtendSession}>Przedłuż sesję</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

SessionWarning.propTypes = {
    initialSeconds: PropTypes.number.isRequired,
    onExtendSession: PropTypes.func.isRequired,
    onTimeout: PropTypes.func.isRequired,
};

SessionWarning.defaultProps = {
    initialSeconds: 30,
};

export default SessionWarning;