import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { createEmptyApplication, getApplicationContent, createEmptyFileData, updateApplicationContent, getApplicationMetadata, getPreviousVersionContent } from '../../services/Application';
import FileUploadComponent from './FileUploadComponent';
import HelpTooltip from './HelpTooltip';
import FileProductsConnector from './FileProductsConnector';
import FileComponentsConnector from './FileComponentsConnector';
import InfoPopup from './InfoPopup';
import { clientCanEdit, operatorCanEdit } from './ReadOnly';
import ReadonlyOverlay from './ReadonlyOverlay';
import CardHeader from './CardHeader';
import ValidationInfo from './ValidationInfo';
import { validateLetters, validateDigits, validateDecimalNumber, validateStep4 } from '../../utils/Validators';
import LoadingSpinner from './LoadingSpinner';
import PreviousValue from './PreviousValue';
import AutoComplete from './AutoComplete';

const ApplicationStep4 = ({ registerCallback, warningsCallback }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();

    const [application, setApplication] = useState(createEmptyApplication());
    const [step4, setStep4] = useState(null);
    const [previousData, setPreviousData] = useState(null);

    const [exportInvoiceFiles, setExportInvoiceFiles] = useState([createEmptyFileData()]);
    const [goodsFiles, setGoodsFiles] = useState([createEmptyFileData()]);
    const [goodsFilesConnections, setGoodsFilesConnections] = useState();
    const [otherGoodsFiles, setOtherGoodsFiles] = useState([createEmptyFileData()]);
    const [otherGoodsFilesConnections, setOtherGoodsFilesConnections] = useState();
    const [productFiles, setProductFiles] = useState([createEmptyFileData()]);
    const [productFilesConnections, setProductFilesConnections] = useState([]);
    const [paymentFiles, setPaymentFiles] = useState([createEmptyFileData()]);

    const [goodsList, setGoodsList] = useState([]);
    const [componentsList, setComponentsList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [infoPopupTrigger, setInfoPopupTrigger] = useState(0);

    const [exportInvoiceValue, setExportInvoiceValue] = useState(null);
    const [exportInvoiceCurrency, setExportInvoiceCurrency] = useState(null);
    const currencyCodes = [
        "PLN", "EUR", "USD", "CHF", "GBP", "JPY", "AUD", "CAD", "CNY", "SEK", "NZD",
        "MXN", "SGD", "HKD", "NOK", "KRW", "TRY", "RUB", "INR", "BRL", "ZAR"
    ];

    const [status, setStatus] = useState(null);
    const [readOnly, setReadOnly] = useState(false);
    const [loading, setLoading] = useState(false);

    const [validators, setValidators] = useState({
        exportInvoice: "false",
        exportInvoiceValue: "false",
        exportInvoiceCurrency: "false"
    });

    const [formValid, setFormValid] = useState(true);

    const [warningPopupTrigger, setWarningPopupTrigger] = useState(0);
    const [errorDescription, setErrorDescription] = useState({ Errors: [], Disclaimer: "" });
    const [allowForwardOnError, setAllowForwardOnError] = useState(false);

    const [exportInvoiceDifferences, setExportInvoiceDifferences] = useState(null);
    const [goodsFilesDifferences, setGoodsFilesDifferences] = useState(null);
    const [otherGoodsFilesDifferences, setOtherGoodsFilesDifferences] = useState(null);
    const [productFilesDifferences, setProductFilesDifferences] = useState(null);
    const [paymentFilesDifferences, setPaymentFilesDifferences] = useState(null);

    const showPreviousData = checkPermission("Obsługa wniosków");
    const debug = false;

    const handleForwardNavigation = () => {
        navigate('/application/edit/' + (parseInt(step) + 1) + '/' + id, { replace: true });
    }

    const handleStep4DataChange = (e) => {
        if (debug) console.log("S4DC: [" + e.target.name + "]: " + e.target.value);
        if (debug) console.log(JSON.stringify(e.target.value));

        setStep4(prevStep4 => {
            return {
                ...prevStep4,
                [e.target.name]: e.target.value
            };
        });
    }

    const validateData = async (application, e) => {
        const errorDescription = { Errors: [], Disclaimer: "" }

        let validationInfo = validateStep4(application.Step_4.Data, application.Step_2.Data);

        if (validationInfo.length > 0) {
            errorDescription.Errors.push({ Header: `Na bieżącej stronie wniosku stwierdzono następujące błędy (${validationInfo.length}):`, Description: validationInfo });
            errorDescription.Disclaimer = "Dane wprowadzone we wniosku wymagają poprawy. Możesz kontynuować wypełnianie wniosku, jednak przed jego wysłaniem konieczna będzie korekta.";
            setWarningPopupTrigger(warningPopupTrigger + 1);
        }
        setErrorDescription(errorDescription);

        warningsCallback(3, validationInfo.length);
        if (e.target.name !== "forward")
            setAllowForwardOnError(false);
        if ((validationInfo.length === 0) && (e.target.name === "forward"))
            handleForwardNavigation();
    };

    const handleSave = async (e) => {
        validateExportInvoice();
        let app = application;
        step4.exportInvoiceFiles = exportInvoiceFiles;
        step4.exportInvoiceValue = exportInvoiceValue;
        step4.exportInvoiceCurrency = exportInvoiceCurrency;
        step4.goodsFiles = goodsFiles;
        step4.goodsFilesConnections = goodsFilesConnections;
        step4.otherGoodsFiles = otherGoodsFiles;
        step4.otherGoodsFilesConnections = otherGoodsFilesConnections;
        step4.productFiles = productFiles;
        step4.productFilesConnections = productFilesConnections;
        step4.paymentFiles = paymentFiles;
        app.Step_4.Data = step4;
        if (debug) console.log(JSON.stringify(step4));
        updateApplicationContent(id, app, userId, accessToken);

        await validateData(app, e);

        setInfoPopupTrigger(infoPopupTrigger + 1);
    };

    const handleForward = async (e) => {
        e.preventDefault();
        await setAllowForwardOnError(true);
        validateExportInvoice();
        handleSave(e);
    };

    const handleBack = async (e) => {
        e.preventDefault();
        navigate('/application/edit/' + (parseInt(step) - 1) + '/' + id, { replace: true });
    };

    const validateField = (name, value) => {
        switch (name) {
            case "exportInvoiceValue":
                return validateDecimalNumber(value, 19, true) ? "false" : "true";
            case "exportInvoiceCurrency":
                return validateLetters(value, 3, true) ? "false" : "true";
            default:
                return "true";
        }
    };

    const handleValidate = (e) => {
        const { name, value } = e.target;
        setValidators(prevValidators => ({
            ...prevValidators,
            [name]: validateField(name, value)
        }));
    };

    const handleFileAdd = (fileType) => {
        const newFile = createEmptyFileData();
        //newFile.id = uuidv4();
        switch (fileType) {
            case "exportInvoiceFiles":
                setExportInvoiceFiles(prevFiles => [...prevFiles, newFile]);
                break;
            case "goodsFiles":
                setGoodsFiles(prevFiles => [...prevFiles, newFile]);
                setGoodsFilesConnections(prevFilesConn => [...prevFilesConn, []]);
                break;
            case "otherGoodsFiles":
                setOtherGoodsFiles(prevFiles => [...prevFiles, newFile]);
                setOtherGoodsFilesConnections(prevFilesConn => [...prevFilesConn, []]);
                break;
            case "productFiles":
                setProductFiles(prevFiles => [...prevFiles, newFile]);
                setProductFilesConnections(prevFilesConn => [...prevFilesConn, []]);
                break;
            case "paymentFiles":
                setPaymentFiles(prevFiles => [...prevFiles, newFile]);
                break;
            default:
                break;
        }
    }

    const handleFileChange = (id, name, description, fileType, index, newFile = true) => {
        const fileInfo = createEmptyFileData();
        fileInfo.id = id;
        fileInfo.name = name;
        fileInfo.description = description;
        if (debug) console.log("S4 hFC: " + fileInfo.id + ", " + fileInfo.name + ", " + fileInfo.description);

        switch (fileType) {
            case "exportInvoiceFiles":
                setExportInvoiceFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            case "goodsFiles":
                setGoodsFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            case "otherGoodsFiles":
                setOtherGoodsFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            case "productFiles":
                setProductFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            case "paymentFiles":
                setPaymentFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            default:
        }
    }

    const handleFileConnectionChange = (data, fileType, index) => {
        if (debug) console.log("HFCC");

        switch (fileType) {
            case "goodsFiles":
                setGoodsFilesConnections(prevFilesConn => {
                    const updatedConnections = [...prevFilesConn];
                    updatedConnections[index] = data;
                    return updatedConnections;
                });
                break;
            case "otherGoodsFiles":
                setOtherGoodsFilesConnections(prevFilesConn => {
                    const updatedConnections = [...prevFilesConn];
                    updatedConnections[index] = data;
                    return updatedConnections;
                });
                break;
            case "productFiles":
                setProductFilesConnections(prevFilesConn => {
                    const updatedConnections = [...prevFilesConn];
                    updatedConnections[index] = data;
                    return updatedConnections;
                });
                break;
            default:
        }
    }

    const handleFileRemove = (fileType, index) => {
        if (debug) console.log("HFR: " + fileType + ", " + index);
        switch (fileType) {
            case "exportInvoiceFiles":
                setExportInvoiceFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                break;
            case "goodsFiles":
                setGoodsFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                setGoodsFilesConnections(prevConns => prevConns.filter((_, i) => i !== index));
                break;
            case "otherGoodsFiles":
                setOtherGoodsFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                setOtherGoodsFilesConnections(prevConns => prevConns.filter((_, i) => i !== index));
                break;
            case "productFiles":
                setProductFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                setProductFilesConnections(prevConns => prevConns.filter((_, i) => i !== index));
                break;
            case "paymentFiles":
                setPaymentFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                break;
            default:
                break;
        }
    };

    const setApplicationMode = async () => {
        const metadata = await getApplicationMetadata(id, accessToken);
        if (metadata) {
            setStatus(metadata.status);
        }

        if (checkPermission("Obsługa wniosków")) {
            if (!operatorCanEdit(metadata.status))
                setReadOnly(true);
        }
        else {
            if (!clientCanEdit(metadata.status))
                setReadOnly(true);
        }
    }

    const compareFiles = (currentData, previousData, setHandler) => {

        let differences = [];
        let differencesSummary = "";

        if (previousData)
            differences = previousData.filter((prevInvoice) => !currentData.some((currInvoice) => currInvoice.id === prevInvoice.id));

        if (differences.length > 0)
            differencesSummary = "<strong>Usunięte pliki:</strong><br/>" + differences.map((file) => "- " + file.name).join(",<br/>");

        differences = currentData ? currentData.filter((currInvoice) => !previousData.some((prevInvoice) => prevInvoice.id === currInvoice.id)) : [];
        if (differences.length > 0) {
            differencesSummary += (differencesSummary.length > 0 ? "<br/>" : "") + "<strong>Dodane pliki:</strong><br/>" + differences.map((file) => "- " + file.name).join(",<br/>");
        }

        //setExportInvoiceDifferences(differencesSummary);
        setHandler(differencesSummary);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (debug) console.log("Pobieranie danych");
                const response = await getApplicationContent(id, accessToken);
                const prevApplication = await getPreviousVersionContent(id, accessToken);
                if (debug) console.log(response.Step_4);
                if (response.Step_4) {
                    setApplication(response);
                    setStep4(response.Step_4.Data);
                    if (response.Step_4.Data.exportInvoiceFiles)
                        setExportInvoiceFiles(response.Step_4.Data.exportInvoiceFiles);
                    else
                        setExportInvoiceFiles([createEmptyFileData()]);
                    setExportInvoiceValue(response.Step_4.Data.exportInvoiceValue ? response.Step_4.Data.exportInvoiceValue : null);
                    setExportInvoiceCurrency(response.Step_4.Data.exportInvoiceCurrency ? response.Step_4.Data.exportInvoiceCurrency : null);

                    if (response.Step_4.Data.goodsFiles)
                        setGoodsFiles(response.Step_4.Data.goodsFiles);
                    else
                        setGoodsFiles([createEmptyFileData()]);

                    if (response.Step_4.Data.goodsFilesConnections)
                        setGoodsFilesConnections(response.Step_4.Data.goodsFilesConnections);
                    else
                        setGoodsFilesConnections([]);

                    if (response.Step_4.Data.otherGoodsFiles)
                        setOtherGoodsFiles(response.Step_4.Data.otherGoodsFiles);
                    else
                        setOtherGoodsFiles([createEmptyFileData()]);

                    if (response.Step_4.Data.goodsFilesConnections)
                        setGoodsFilesConnections(response.Step_4.Data.goodsFilesConnections);
                    else
                        setGoodsFilesConnections([]);

                    if (response.Step_4.Data.otherGoodsFilesConnections)
                        setOtherGoodsFilesConnections(response.Step_4.Data.otherGoodsFilesConnections);
                    else
                        setOtherGoodsFilesConnections([]);

                    if (response.Step_4.Data.productFiles)
                        setProductFiles(response.Step_4.Data.productFiles);
                    else
                        setProductFiles([createEmptyFileData()]);

                    if (response.Step_4.Data.productFilesConnections)
                        setProductFilesConnections(response.Step_4.Data.productFilesConnections);
                    else
                        setProductFilesConnections([]);

                    if (response.Step_4.Data.paymentFiles)
                        setPaymentFiles(response.Step_4.Data.paymentFiles);
                    else
                        setPaymentFiles([createEmptyFileData()]);
                }

                if (response.Step_2) {
                    setGoodsList(response.Step_2.Data.Products.filter((product) => product.exporter === "true"));
                    setProductsList(response.Step_2.Data.Products.filter((product) => product.producer === "true"));
                }

                if (response.Step_3) {
                    let tempComponentsList = [];
                    response.Step_3.Data.Processes.forEach(process => {
                        process.Products.forEach(product => {
                            product.Components.forEach(component => {
                                tempComponentsList.push(product.name + " > " + component.componentName);
                            });
                        });
                    });
                    setComponentsList(tempComponentsList);
                }

                if (prevApplication && prevApplication.Step_4) {
                    setPreviousData(prevApplication.Step_4.Data);
                    compareFiles(response.Step_4.Data.exportInvoiceFiles, prevApplication.Step_4.Data.exportInvoiceFiles, setExportInvoiceDifferences);
                    compareFiles(response.Step_4.Data.goodsFiles, prevApplication.Step_4.Data.goodsFiles, setGoodsFilesDifferences);
                    compareFiles(response.Step_4.Data.otherGoodsFiles, prevApplication.Step_4.Data.otherGoodsFiles, setOtherGoodsFilesDifferences);
                    compareFiles(response.Step_4.Data.productFiles, prevApplication.Step_4.Data.productFiles, setProductFilesDifferences);
                    compareFiles(response.Step_4.Data.paymentFiles, prevApplication.Step_4.Data.paymentFiles, setPaymentFilesDifferences);
                }

                setApplicationMode();

            } catch (error) {
                console.error('Failed to fetch application content:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        validateExportInvoice();
    }, [exportInvoiceFiles, goodsFiles, goodsList, otherGoodsFiles, productFiles, productFilesConnections, paymentFiles])

    const validateExportInvoice = () => {
        let value = "false";
        if ((exportInvoiceFiles.length === 1) && (exportInvoiceFiles[0].id === ""))
            value = "true";

        setValidators(prevValidators => ({
            ...prevValidators,
            exportInvoice: value
        }));
    }

    const countWarnings = () => {
        let count = 0;
        for (const key in validators) {
            if (validators.hasOwnProperty(key) && ((validators[key] === "true") || validators[key] === true)) {
                count++;
            }
        }
        return count;
    }

    useEffect(() => {
        warningsCallback(3, countWarnings());
    }, [validators]);

    return (
        <div className='pb-2'>
            <CardHeader
                text="Załączniki obowiązkowe"
                commentId="4-1"
                commentVisible={status !== 1}
                registerCallback={registerCallback}
                viewAll={checkPermission("Obsługa wniosków")}
            />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <div className='d-flex flex-row'>
                        <strong>Faktura eksportowa lub faktura pro-forma dotycząca transakcji objętej świadectwem *</strong>
                        <HelpTooltip title='Jeżeli wystawiono więcej niż jeden dokument, załącz wszystkie. Dodatkowo można dołączyć packing list, specyfikację wysyłkową, "Bill of lading” itp.' wide="true" />
                        <ValidationInfo title="Załączenie faktury jest obowiązkowe" visible={validators.exportInvoice} />
                    </div>

                    <div>
                        <PreviousValue show={showPreviousData && previousData && (exportInvoiceDifferences.length > 0)} previousValue={exportInvoiceDifferences} header='Wprowadzone zmiany<hr/>' left={true} wide={true}>
                            {((exportInvoiceFiles.length === 0 || exportInvoiceFiles[0].id === "") && [true, "true"].includes(readOnly)) ?
                                (<div className='mt-1'>Brak plików</div>)
                                :
                                (exportInvoiceFiles.map((file, index) => (
                                    <div key={"exportInvoiceFiles_" + file.id} className='mt-1'>
                                        <FileUploadComponent
                                            fileId={file.id}
                                            fileName={file.name}
                                            fileDescription={file.description}
                                            fileType="exportInvoiceFiles"
                                            index={index}
                                            onChange={handleFileChange}
                                            onRemove={() => handleFileRemove("exportInvoiceFiles", index)}
                                            readOnly={readOnly ? "true" : "false"}
                                            showReadOnly={false}
                                            buttonLabel={index === 0 ? "Dodaj plik" : "Dodaj kolejny plik"}
                                        />
                                    </div>
                                )
                                ))}
                        </PreviousValue>
                    </div>

                    {exportInvoiceFiles.length < 1 && (
                        <div className='me-auto'>
                            <button type="button" className="btn bt</div>n-outline-primary h2 w7 rounded-2" onClick={() => { handleFileAdd("exportInvoiceFiles") }}>
                                Dodaj plik
                            </button>
                        </div>
                    )}

                    <div className='mt-1'>
                        <ReadonlyOverlay readonly={readOnly}>
                            <div className='d-flex flex-row w47'>
                                <div className='d-flex flex-column w11'>
                                    <div className='d-flex flex-row'>
                                        <label htmlFor="exportInvoiceValue" className="form-label">Wartość eksportu *</label>
                                        <ValidationInfo title="Wypełnienie pola jest obowiązkowe. Maksymalna ilość znaków - 15" visible={validators.exportInvoiceValue} />
                                    </div>
                                    <PreviousValue show={showPreviousData && previousData && (previousData.exportInvoiceValue !== exportInvoiceValue)} previousValue={previousData ? previousData.exportInvoiceValue : null}>
                                        <input
                                            type="text"
                                            className="form-control w11 h2"
                                            id="exportInvoiceValue"
                                            name="exportInvoiceValue"
                                            maxLength="19"
                                            value={exportInvoiceValue || ""}
                                            onChange={(e) => {
                                                const formattedValue = e.target.value
                                                    .replace(/[^0-9.,]/g, '')
                                                    .replace(/,/g, '.')
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                                    .replace(/(\.\d{2})\d+/, '$1')
                                                    .replace(/(\..*)\./g, '$1'); // Ensure only one decimal separator
                                                    setExportInvoiceValue(formattedValue);
                                            }}
                                            onBlur={handleValidate}
                                            onFocus={() => validateExportInvoice()}
                                            invalid={validators.exportInvoiceValue}
                                        />
                                    </PreviousValue>
                                </div>
                                <div className='d-flex flex-column ms-1 w7'>
                                    <PreviousValue show={showPreviousData && previousData && (previousData.exportInvoiceCurrency !== exportInvoiceCurrency)} previousValue={previousData ? previousData.exportInvoiceCurrency : null}>
                                        <div className='d-flex flex-row'>
                                            <label htmlFor="exportInvoiceCurrency" className="form-label">Waluta *</label>
                                            <ValidationInfo title="Wypełnienie pola jest obowiązkowe. Maksymalna ilość znaków - 3" visible={validators.exportInvoiceCurrency} />
                                        </div>
                                        <select
                                            className="form-control form-select w7 h2"
                                            id="exportInvoiceCurrency"
                                            name="exportInvoiceCurrency"
                                            value={exportInvoiceCurrency || ""}
                                            onChange={(e) => { setExportInvoiceCurrency(e.target.value); }}
                                            onBlur={handleValidate}
                                            invalid={validators.exportInvoiceCurrency}
                                        >
                                            <option value="">Wybierz walutę</option>
                                            {currencyCodes.map((currency, index) => (
                                                <option key={"currency_" + index} value={currency}>{currency}</option>
                                            ))}
                                        </select>
                                        {false && (<AutoComplete
                                            options={currencyCodes}
                                            value={exportInvoiceCurrency || ""}
                                            invalid={validators.exportInvoiceCurrency}
                                            onChange={(e) => setExportInvoiceCurrency(e.target.value)}
                                            onBlur={handleValidate}
                                            placeholder={"Wybierz walutę"}
                                        />)}
                                    </PreviousValue>
                                </div>
                            </div>
                        </ReadonlyOverlay>
                    </div>
                </div>
            </div>

            {goodsList.length > 0 && (
                <div>
                    <CardHeader
                        text="Załączniki do towarów"
                        commentId="4-2" 
                        commentVisible={status !== 1} 
                        registerCallback={registerCallback}
                        viewAll={checkPermission("Obsługa wniosków")}
                    />
                    <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                        <div className='d-flex flex-column w47'>
                            <span className='pb-1'>W poprzednim kroku ustaliliśmy, że <strong>nie jesteś</strong> producentem następujących towarów:</span>

                            <ul>
                                {goodsList.map((goods, index) => (
                                    <li key={"goods_" + index}>
                                        {goods.productName}
                                    </li>
                                )
                                )}
                            </ul>

                            <span className='pt-1'>
                                Aby ustalić pochodzenie, musisz załadować do systemu <strong>faktury zakupu wskazanych towarów</strong>.
                                Dodając każdą fakturę, oznacz towary, których ona dotyczy. Jedna faktura może dotyczyć kilku eksportowanych towarów. Jeden eksportowany towar mogłeś kupić na podstawie kilku faktur.
                            </span>
                        </div>

                        <div className='mt-1'>
                            <strong>Załącz faktury zakupu towarów</strong>
                        </div>

                        <div>
                            <PreviousValue show={showPreviousData && previousData && (goodsFilesDifferences.length > 0)} previousValue={goodsFilesDifferences} header='Wprowadzone zmiany<hr/>' left={true} wide={true}>
                                {((goodsFiles.length === 0 || goodsFiles[0].id === "") && [true, "true"].includes(readOnly)) ?
                                    (<div className='mt-1'>Brak plików</div>)
                                    :
                                    (goodsFiles.map((file, index) => (
                                        <div className='mt-1 pb-1 border-bottom' key={"goodsFiles_" + file.id}>
                                            <FileUploadComponent
                                                fileId={file.id}
                                                fileName={file.name}
                                                fileDescription={file.description}
                                                fileType="goodsFiles"
                                                index={index}
                                                onChange={handleFileChange}
                                                onRemove={() => handleFileRemove("goodsFiles", index)}
                                                readOnly={readOnly ? "true" : "false"}
                                                showReadOnly={false}
                                                buttonLabel={index === 0 ? "Dodaj plik" : "Dodaj kolejny plik"}
                                                />
                                            {((goodsList.length > 0 && (![null, ""].includes(file.id))) && (
                                                <div className='mt-1'>
                                                    <FileProductsConnector
                                                        index={index}
                                                        data={goodsFilesConnections[index]}
                                                        productsList={goodsList}
                                                        fileType="goodsFiles"
                                                        onDataChange={handleFileConnectionChange}
                                                        readOnly={readOnly ? "true" : "false"}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )))}
                            </PreviousValue>
                        </div>

                        {goodsFiles.length < 1 && (
                            <div className='me-auto'>
                                <button type="button" className="btn btn-outline-primary h2 w7 rounded-2" onClick={() => { handleFileAdd("goodsFiles") }}>
                                    Dodaj plik
                                </button>
                            </div>)}

                        <div className='pt-2 d-flex flex-column'>
                            <strong className='pb-1'>
                                Inne załączniki, które mogą pomóc przy wystawieniu Świadectwa:
                            </strong>

                            <ul>
                                <li>
                                    Dołącz dokumenty potwierdzające pochodzenie, np. świadectwa pochodzenia / deklaracje o kraju pochodzenia / inne dokumenty, na których jest zapis o kraju pochodzenia.
                                </li>

                                <li>
                                    Jeśli zakupiłeś towar na terenie UE - dodatkowo dołącz dokumenty potwierdzające dostawę do Polski na przykład CMR-ki
                                </li>
                            </ul>

                        </div>

                        <div>
                            <PreviousValue show={showPreviousData && previousData && (otherGoodsFilesDifferences.length > 0)} previousValue={otherGoodsFilesDifferences} header='Wprowadzone zmiany<hr/>' left={true} wide={true}>
                                <div>
                                    {((otherGoodsFiles.length === 0 || otherGoodsFiles[0].id === "") && [true, "true"].includes(readOnly)) ?
                                        (<div className='mt-1'>Brak plików</div>)
                                        :
                                        (otherGoodsFiles.map((file, index) => (
                                            <div className='mt-1 pb-1 border-bottom' key={"otherGoodsFiles_" + file.id}>
                                                <FileUploadComponent
                                                    fileId={file.id}
                                                    fileName={file.name}
                                                    fileDescription={file.description}
                                                    fileType="otherGoodsFiles"
                                                    index={index}
                                                    onChange={handleFileChange}
                                                    onRemove={() => handleFileRemove("otherGoodsFiles", index)}
                                                    readOnly={readOnly ? "true" : "false"}
                                                    showReadOnly={false}
                                                    buttonLabel={index === 0 ? "Dodaj plik" : "Dodaj kolejny plik"}
                                                    />
                                                {((goodsList.length > 0 && (![null, ""].includes(file.id))) && (
                                                    <div className='mt-1'>
                                                        <FileProductsConnector
                                                            index={index}
                                                            data={otherGoodsFilesConnections[index]}
                                                            productsList={goodsList}
                                                            fileType="otherGoodsFiles"
                                                            onDataChange={handleFileConnectionChange}
                                                            readOnly={readOnly ? "true" : "false"}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )))}
                                </div>
                            </PreviousValue>
                        </div>
                    </div>
                </div>
            )}

            {productsList.length > 0 && (
                <div>
                    <CardHeader
                        text="Załączniki do produktów"
                        commentId="4-3"
                        commentVisible={status !== 1} 
                        registerCallback={registerCallback}
                        viewAll={checkPermission("Obsługa wniosków")}
                    />
                    <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                        <div className='d-flex flex-column w47'>
                            <span className='pb-1'>W poprzednim kroku ustaliliśmy, że <strong>jesteś</strong> producentem następujących produktów, które będą podlegały eksportowi:</span>

                            <ul>
                                {productsList.map((product, index) => (
                                    <li key={"product_" + index}>
                                        {product.productName}
                                    </li>
                                )
                                )}
                            </ul>

                            <span className='pt-1'>
                                Aby ustalić pochodzenie, możesz dodać do wniosku dokumenty dotyczące surowców, z których wytworzono produkty.<br/>Jeden załącznik może dotyczyć kilku eksportowanych produktów. Do jednego produktu możesz dodać kilka załączników.
                            </span>

                            <span className='py-1'>
                                Przykładowe załączniki to:
                            </span>

                            <ul>
                                <li>
                                    kalkulacja kosztów wytworzenia
                                </li>

                                <li>
                                    faktury zakupu surowców / komponentów użytych w procesie produkcji
                                </li>

                                <li>
                                    dokumenty potwierdzające pochodzenie surowców / komponentów użytych w procesie produkcji np. świadectwa pochodzenia, deklaracje o kraju pochodzenia, inne dokumenty, na których jest zapis o kraju pochodzenia
                                </li>

                                <li>
                                    inne dodatkowe dokumenty i oświadczenia wymagane na etapie weryfikacji
                                </li>
                            </ul>
                        </div>

                        <div className='py-1'>
                            <strong>
                                Załącz dokumenty
                            </strong>
                        </div>

                        <div>
                            <PreviousValue show={showPreviousData && previousData && (productFilesDifferences.length > 0)} previousValue={productFilesDifferences} header='Wprowadzone zmiany<hr/>' left={true} wide={true}>
                                <div>
                                    {((productFiles.length === 0 || productFiles[0].id === "") && [true, "true"].includes(readOnly)) ?
                                        (<div className='mt-1'>Brak plików</div>)
                                        :
                                        (
                                            productFiles.map((file, index) => (
                                                <div className='mt-1 pb-1 border-bottom' key={index}>
                                                    <FileUploadComponent
                                                        fileId={file.id}
                                                        fileName={file.name}
                                                        fileDescription={file.description}
                                                        fileType="productFiles"
                                                        index={index}
                                                        onChange={handleFileChange}
                                                        onRemove={() => handleFileRemove("productFiles", index)}
                                                        readOnly={readOnly ? "true" : "false"}
                                                        showReadOnly={false}
                                                        buttonLabel={index === 0 ? "Dodaj plik" : "Dodaj kolejny plik"}
                                                        />
                                                    {((componentsList.length > 0 && (![null, ""].includes(file.id))) && (
                                                        <div className='mt-1'>
                                                            <FileComponentsConnector
                                                                index={index}
                                                                data={productFilesConnections[index]}
                                                                componentsList={componentsList}
                                                                fileType="productFiles"
                                                                onDataChange={handleFileConnectionChange}
                                                                readOnly={readOnly ? "true" : "false"}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )))}
                                </div>
                            </PreviousValue>
                        </div>

                        {productFiles.length < 1 && (
                            <div className='me-auto pb-4'>
                                <button type="button" className="btn btn-outline-primary py-2 rounded-2 w7" onClick={() => { handleFileAdd("productFiles") }}>
                                    Dodaj plik
                                </button>
                            </div>)}
                    </div>
                </div>
            )}

            <div className='mb-3'>
                <CardHeader
                    text="Załączniki - dodatkowe informacje"
                    commentId="4-4"
                    commentVisible={status !== 1}
                    commentLocation="Załączniki - dod. info."
                    registerCallback={registerCallback}
                    viewAll={checkPermission("Obsługa wniosków")}
                />
                <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                    <div className='d-flex flex-column w47'>
                        <div className='d-flex flex-row'>
                            Jeżeli wymagane są dodatkowe zapisy w treści świadectwa, załącz dokument, z którego będą one wynikać (np. akredytywa, kontrakt). Możesz tutaj dodać także inne załączniki.
                        </div>

                        {loading ? (
                            <LoadingSpinner text='Pobieranie informacji o plikach' />
                        ) : (
                            <PreviousValue show={showPreviousData && previousData && paymentFilesDifferences && (paymentFilesDifferences.length > 0)} previousValue={paymentFilesDifferences} header='Wprowadzone zmiany<hr/>' left={true} wide={true}>
                            {((paymentFiles.length === 0 || paymentFiles[0].id === "") && [true, "true"].includes(readOnly)) ?
                                (<div className='mt-1'>Brak plików</div>)
                                :
                                (paymentFiles.map((file, index) => (
                                    <div key={"exportInvoiceFiles_" + file.id} className='mt-1'>
                                        <FileUploadComponent
                                            fileId={file.id}
                                            fileName={file.name}
                                            fileDescription={file.description}
                                            fileType="paymentFiles"
                                            index={index}
                                            onChange={handleFileChange}
                                            onRemove={() => handleFileRemove("paymentFiles", index)}
                                            readOnly={readOnly ? "true" : "false"}
                                            showReadOnly={false}
                                            buttonLabel={index === 0 ? "Dodaj plik" : "Dodaj kolejny plik"}
                                        />
                                    </div>
                                )
                                ))}
                            </PreviousValue>
                        )}
                    </div>
                </div>
            </div>

            {!readOnly && (
                <div className="flex-column">
                    <form>
                        <div className="d-flex flex-row ms-4 w55">
                            <button
                                className="btn btn-outline-primary me-auto w11 h2"
                                onClick={handleBack}
                            >
                                Wstecz
                            </button>

                            <button
                                type="button"
                                name="save"
                                className="btn btn-outline-primary w11 h2"
                                onClick={handleSave}
                            >
                                Zapisz wersję roboczą
                            </button>

                            <button
                                type="button"
                                name="forward"
                                className="btn btn-primary ms-1 w11 h2"
                                onClick={handleForward}
                                disabled={!formValid}
                            >
                                Zapisz i przejdź dalej
                            </button>
                        </div>
                    </form>
                </div>
            )}

            <InfoPopup title={"Zapisano wersję roboczą wniosku."} trigger={infoPopupTrigger} />
            <InfoPopup
                title={""}
                errorDescription={errorDescription}
                trigger={warningPopupTrigger}
                errorMode={true}
                actionOnAccept={handleForwardNavigation}
                allowForward={allowForwardOnError}
            />
        </div>
    );
};

export default ApplicationStep4;
