import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { ApiHost } from '../utils/ApiConfig';

const debug = false;

const getPagesCount = async (applicationId, accessToken) => {
    try {
        if (debug) console.log("Pobieranie liczby stron świadectwa.");
        const response = await axios.get(ApiHost + `/certificate/count_pages/` + applicationId, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            return response.data.Count;
        }
        return 1;
    } catch (error) {
        console.error("Błąd podczas pobierania liczby stron świadectwa: ", error);
        return 0;
    }
};

const getPagesCountApplication = async (applicationId, accessToken) => {
    try {
        if (debug) console.log("Pobieranie liczby stron wniosku.");
        const response = await axios.get(ApiHost + `/certificate/count_pages_application/` + applicationId, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            return response.data.Count;
        }
        return 1;
    } catch (error) {
        console.error("Błąd podczas pobierania liczby stron świadectwa: ", error);
        return 0;
    }
};

export {
    getPagesCount,
    getPagesCountApplication
};