import React, { useEffect, useState } from 'react';
import ProductComponent from "./ProductComponent";
import { createEmptyProductComponent } from '../../services/Application';
import CommunicationSpot from './CommunicationSpot';
import PreviousValue from './PreviousValue';

const ProductDetails = ({ index, data, previousData, showPreviousData = false, onDataChange, onRemove, name, commentVisible = true, registerCallback, viewAll = false, handleValidate }) => {
    const processIndex = name.split("_")[1];
    const [productData, setProductData] = useState(data);
    const [removedComponentIndexes, setRemovedComponentIndexes] = useState([]);
    const [validators, setValidators] = useState({});
    const [componentDifferences, setComponentDifferences] = useState(null);
    
    const setValidator = (name, validator) => {
        setValidators(prevValidators => {
            return {
                ...prevValidators,
                [name]: validator
            };
        });
    };

    const removeValidator = (name) => {
        setValidators(prevValidators => {
            const updatedValidators = { ...prevValidators };
            delete updatedValidators[name];
            return updatedValidators;
        });
    };

    const handleAddComponent = () => {
        setProductData(prevProductData => {
            const updatedComponents = [...prevProductData.Components, createEmptyProductComponent()];
            const updatedData = { ...prevProductData, Components: updatedComponents };
            onDataChange(updatedData, index);
            return updatedData;
        });
    };

    const handleUpdateComponent = (component, cIndex) => {
        setProductData(prevProductData => {
            const updatedComponents = [...prevProductData.Components];
            updatedComponents[cIndex] = component;
            const updatedData = { ...prevProductData, Components: updatedComponents };
            onDataChange(updatedData, index);
            return updatedData;
        });
    };

    const handleRemoveComponent = (cIndex) => {
        setRemovedComponentIndexes(prevIndexes => [...prevIndexes, cIndex]);
        removeValidator("ProductComponent_" + processIndex + "_" + index + "_" + cIndex);
    };

    const compareComponents = async () => {
        let differences = [];
        let differencesSummary = "";

        if (previousData.Components) {
            differences = previousData.Components.filter(prevComponent => !productData.Components.some(component => component.componentName === prevComponent.componentName));
        }
        if (differences.length > 0)
            differencesSummary = "<strong>Usunięte komponenty:</strong><br/>" + differences.map(component => "- " + component.componentName).join(",<br/>");

        if (previousData.Components && previousData.Components.length > 0)
            differences = productData.Components.filter(component => !previousData.Components.some(prevComponent => prevComponent.componentName === component.componentName));
        if (differences.length > 0)
            differencesSummary += (differencesSummary.length > 0 ? "<br/>" : "") + "<strong>Dodane komponenty:</strong><br/>" + differences.map(component => "- " + component.componentName).join(",<br/>");

        setComponentDifferences(differencesSummary);
    }

    const findMatchingPreviousComponent = (component) => {
        if (previousData.Components)
            return previousData.Components.find(prevComponent => prevComponent.componentName === component.componentName);
    }

    useEffect(() => {
        const filteredComponents = productData.Components.filter((_, index) => !removedComponentIndexes.includes(index));
        const updatedData = { ...productData, Components: filteredComponents };
        onDataChange(updatedData, index);
    }, [removedComponentIndexes]);

    useEffect(() => {
        let count = 0;
        for (const key in validators) {
            if (validators.hasOwnProperty(key) && ((validators[key] === "true") || validators[key] === true)) {
                count++;
            }
        }
        handleValidate(name, (count === 0) ? "false" : "true");
    }, [validators]);

    useEffect(() => {
        compareComponents();
    }, [productData]);

    return (
        <div>
            <div className="d-flex flex-row w-100 mb-1 fs-6">
                <div className='me-auto'>
                    <strong>{(index + 1) + ". " + productData.name}</strong>
                </div>
                <CommunicationSpot
                    commentId={"3-1-" + processIndex + "-" + index} location="Proces produkcji - Produkt" isVisible={commentVisible} registerCallback={registerCallback}
                    viewAll={viewAll}
                />
                <button type="button" key={"ProductDetailsRemove_" + processIndex + "_" + index} className="btn btn-outline-primary w7 h2 rounded-2 mt-auto ms-1" onClick={() => onRemove(productData.name)} title="Usuń produkt">
                    Usuń produkt
                </button>
            </div>

            {(componentDifferences !== null) && (
                <div className="w-100">
                    <PreviousValue show={showPreviousData && componentDifferences.length > 0} previousValue={componentDifferences} wide={true} header='Wprowadzone zmiany<hr/>'>
                        {productData.Components.map((component, pcIndex) => (
                            !removedComponentIndexes.includes(pcIndex) && (
                                <div key={"ProductComponentDiv_" + processIndex + "_" + index + "_" + pcIndex}>
                                    <ProductComponent
                                        name={"ProductComponent_" + processIndex + "_" + index + "_" + pcIndex}
                                        key={"ProductComponent_" + processIndex + "_" + index + "_" + pcIndex}
                                        index={pcIndex}
                                        data={component} onDataChange={handleUpdateComponent}
                                        previousData={findMatchingPreviousComponent(component)}
                                        showPreviousData={showPreviousData}
                                        onRemove={() => handleRemoveComponent(pcIndex)}
                                        handleValidate={setValidator}
                                    />
                                </div>
                            )
                        ))}
                    </PreviousValue>

                    <div className="d-flex flex-row mt-1 me-4">
                        <button type="button" className="btn btn-primary w9 h2 rounded-2 mt-auto me-auto" onClick={handleAddComponent} title="Dodaj kolejny materiał/surowiec/komponent">
                            Dodaj kolejny
                        </button>
                    </div>
                </div>
            )}
        </div>);
}

export default ProductDetails;