import React, { useState, useEffect } from 'react';

function FileComponentsConnector({ index, data = [], componentsList, fileType, onDataChange, readOnly = "false" }) {
    const [components] = useState(componentsList.sort((a, b) => a.localeCompare(b)));
    const [connectedComponents, setConnectedComponents] = useState(data.sort((a, b) => a.localeCompare(b)));
    const [filter, setFilter] = useState("");
    const [showSelector, setShowSelector] = useState(false);

    if (showSelector === false) {
        return (
            <div className='w-100'>
                {connectedComponents.length > 0 ? (
                    <div className='d-flex flex-column'>
                        <span className='mb-1'>Powiązane materiały, surowce lub komponenty:</span>
                        <ul>
                            {connectedComponents.map((component, indx) => (
                                <li key={"connectedComponent_" + indx}>
                                    {component}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div className=''>
                        Brak powiązanych materiałów, surowców lub komponentów.
                    </div>
                )}
                {[false, "false"].includes(readOnly) && (
                    <button type="button" className="btn btn-outline-primary w21 mt-1" onClick={() => setShowSelector(true)}>
                        Wybierz komponenty, których dotyczy ten dokument
                    </button>
                )}
            </div>
        );
    }

    // Funkcja dodająca zaznaczenie wszystkich komponentów
    const selectAll = () => {
        setFilter("");
        setConnectedComponents(components);
        onDataChange(components, fileType, index);
    }

    const clearAll = () => {
        setFilter("");
        setConnectedComponents([]);
        onDataChange([], fileType, index);
    }

    return (
        <div className='w-100'>
            <div className='d-flex flex-row'>
                <input type="text" className="form-control rounded-end-0 h2 w23" placeholder="Filtruj materiały, surowce lub komponenty" value={filter} onChange={(e) => setFilter(e.target.value)} />
                <button type="button" className="btn btn-outline-secondary border border-start-0 rounded-start-0 h2 tooltip text-dark" onClick={() => setFilter("")}>X<span className='tooltiptext'>Wyczyść filtr</span></button>
                <button type="button" className="btn btn-outline-primary w9 h2 ms-auto" onClick={() => selectAll()}>
                    Zaznacz wszystkie
                </button>
                <button type="button" className="btn btn-outline-primary w11 h2 ms-1" onClick={() => clearAll()}>
                    Wyczyść zaznaczenie
                </button>
            </div>

            {(((components.length > 0) && ([false, "false"].includes(readOnly))) ? (
                components.filter((component) => component.toLowerCase().includes(filter.toLowerCase())).map((component, indx) => (
                    <div key={"connectedComponent_" + indx} className='d-flex flex-row mt-1'>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={"connectedComponent_" + indx}
                            value={component}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setConnectedComponents(prevComponents => {
                                        return [...prevComponents, component];
                                    });
                                    onDataChange([...connectedComponents, component], fileType, index);
                                } else {
                                    setConnectedComponents(prevComponents => {
                                        return prevComponents.filter((comp) => comp !== component);
                                    });
                                    onDataChange(connectedComponents.filter((comp) => comp !== component), fileType, index);
                                }

                            }}
                            checked={connectedComponents.includes(component)}
                        />
                        <label className="form-check-label" htmlFor={"connectedComponent_" + indx}>{component}</label>
                    </div>
                ))
            ) : (
                <div className='d-flex flex-row mb-1'>
                    Materiały, surowce lub komponenty, których dotyczy ten dokument
                </div>
            ))
            }
            <button type="button" className="btn btn-outline-primary rounded-2 w7 h2 mt-1" onClick={() => setShowSelector(false)}>
                Zatwierdź
            </button>
        </div>
    );
}

export default FileComponentsConnector;
