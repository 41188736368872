import React, {useState, useEffect} from 'react';
import { getApplicationComment } from '../../services/Application';
import './CaseMessages.css'; // Assuming you have some CSS for styling
import CardHeader from './CardHeader';
import LoadingSpinner from './LoadingSpinner';
import CommunicationPanel from './CommunicationPanel';
import { useAuth } from '../../context/AuthContext';

const CaseMessages = ({ caseData }) => {
    const { accessToken } = useAuth();
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getApplicationComment(caseData.id, 5, "CaseCommunication", accessToken);
                if (response) {
                    setMessages(response);
                }
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch application comments:', error);
            }
        };

        fetchData();
    }, [caseData.id]);

    if (loading) {
        return (
            <div>
            <CardHeader text="Wiadomości do operatora" onlyText="true" />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <LoadingSpinner />
                </div>
            </div>
        </div>
        );
    }
    return (
        <div>
            <CardHeader text="Wiadomości do operatora" onlyText="true" />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <CommunicationPanel
                        data={messages}
                        isVisible={true}
                        embedded={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default CaseMessages;