import React, { useState, useEffect } from 'react';
import HelpTooltip from './HelpTooltip';
import ValidationInfo from './ValidationInfo';
import { validateLettersDigits, validateLettersDigitsSpecChars } from '../../utils/Validators';
import PreviousValue from './PreviousValue';
import LoadingSpinner from './LoadingSpinner';
import { useAuth } from '../../context/AuthContext';

const tooltips = [
    "Możesz podać dane liczbowo-opisowe,<br/>na przykład: <br/>1 kontener, <br/>3 palety, <br/>200 litrów",
    "Możesz podać łączną wagę netto",
    "Możesz podać łączną wagę brutto"
];

function ProductSummary({ data = {}, previousData = {}, showPreviousData = false, onDataChange }) {
        const { checkPermission } = useAuth();
    
    let {
        totalQuantity = '',
        totalNetWeight = '',
        totalGrossWeight = '',
        countriesOfOrigin = '',
        transportDetails = '',
        productsRemarks = '',
        productsSummaryEdit = '',
        quantitySummaryEdit = '',
        editCountriesOfOrigin = false,
        editSummaries = false
    } = data;

    let {
        prevTotalQuantity = '',
        prevTotalNetWeight = '',
        prevTotalGrossWeight = '',
        prevCountriesOfOrigin = '',
        prevTransportDetails = '',
        prevProductsRemarks = '',
        prevProductsSummaryEdit = '',
        prevQuantitySummaryEdit = '',
        prevEditCountriesOfOrigin = false,
        prevEditSummaries = false
    } = {
        prevTotalQuantity: previousData.totalQuantity,
        prevTotalNetWeight: previousData.totalNetWeight,
        prevTotalGrossWeight: previousData.totalGrossWeight,
        prevCountriesOfOrigin: previousData.countriesOfOrigin || "",
        prevTransportDetails: previousData.transportDetails,
        prevProductsRemarks: previousData.productsRemarks,
        prevProductsSummaryEdit: previousData.productsSummaryEdit,
        prevQuantitySummaryEdit: previousData.quantitySummaryEdit,
        prevEditCountriesOfOrigin: previousData.editCountriesOfOrigin || "false",
        prevEditSummaries: previousData.editSummaries
    };

    const [validators, setValidators] = useState({
        totalQuantity: "",
        totalNetWeight: "",
        totalGrossWeight: "",
        countriesOfOrigin: "",
        transportDetails: "",
        productsRemarks: ""
    });
    const [isLoading, setIsLoading] = useState(true);

    const validateField = (name, value) => {
        switch (name) {
            case "totalQuantity":
                return validateLettersDigits(value, 33, false) ? "false" : "true";
            case "totalNetWeight":
                return validateLettersDigits(value, 22, false) ? "false" : "true";
            case "totalGrossWeight":
                return validateLettersDigits(value, 20, false) ? "false" : "true";
            case "countriesOfOrigin":
                return validateLettersDigitsSpecChars(value, 160, false, 4) ? "false" : "true";
            case "transportDetails":
                return validateLettersDigitsSpecChars(value, 280, false, 7) ? "false" : "true";
            case "productsRemarks":
                return validateLettersDigitsSpecChars(value, 360, false, 9) ? "false" : "true";
            default:
                return "true";
        }
    };

    const handleProductDataChange = (e) => {
        const { name, value, type, checked } = e.target;
        let newValue = type === "checkbox" ? checked : value.replace(/\t/g, " ");
        onDataChange(name, newValue);
        setValidators(prevValidators => ({
            ...prevValidators,
            [name]: validateField(name, newValue)
        }));
    };

    useEffect(() => {
        setValidators({
            totalQuantity: validateField("totalQuantity", totalQuantity),
            totalNetWeight: validateField("totalNetWeight", totalNetWeight),
            totalGrossWeight: validateField("totalGrossWeight", totalGrossWeight),
            countriesOfOrigin: validateField("countriesOfOrigin", countriesOfOrigin),
            transportDetails: validateField("transportDetails", transportDetails),
            productsRemarks: validateField("productsRemarks", productsRemarks)
        });
        setIsLoading(false);
    }, [totalQuantity, totalNetWeight, totalGrossWeight, countriesOfOrigin, transportDetails, productsRemarks]);

    return (
        <div>
            {isLoading ?
                (<LoadingSpinner text="Ładowanie danych..." />)
                :
                (
                    <div className="d-flex flex-column">
                        {((checkPermission("Obsługa wniosków")) || ([true, "true"].includes(editCountriesOfOrigin))) && (
                            <div className='w-100 pb-2 mb-1 border-bottom'>
                            <div className='d-flex flex-row'>
                                <h4>Kraj pochodzenia</h4>
                            </div>
                            {checkPermission("Obsługa wniosków") && (
                                <div className="d-flex flex-row mb-1">
                                <PreviousValue show={showPreviousData && (prevEditCountriesOfOrigin !== editCountriesOfOrigin)} previousValue={["true", true].includes(prevEditCountriesOfOrigin) ? "Tak" : "Nie"}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="editCountriesOfOrigin"
                                            name="editCountriesOfOrigin"
                                            onChange={handleProductDataChange}
                                            checked={[true, "true"].includes(editCountriesOfOrigin)}
                                        />
                                        <label className="form-check-label" htmlFor="editCountriesOfOrigin">
                                            Chcę wprowadzić ręcznie widoczną na świadectwie listę krajów pochodzenia
                                        </label>
                                    </div>
                                </PreviousValue>
                            </div>
                            )}
                            {[true, "true"].includes(editCountriesOfOrigin) && (
                                <div className='d-flex flex-column w23'>
                                    <div className='d-flex align-items-center'>
                                        <label className="form-label">Kraj pochodzenia</label>
                                        <ValidationInfo title="Maksymalna ilość znaków - 160, maksymalnie 4 wiersze" visible={validators.countriesOfOrigin} />
                                    </div>
                                    <PreviousValue show={showPreviousData && (countriesOfOrigin !== prevCountriesOfOrigin)} previousValue={prevCountriesOfOrigin}>
                                            {checkPermission("Obsługa wniosków") ? (
                                                <textarea
                                                    className="form-control my-0 w23 h6 rounded-3"
                                                    maxLength="163"
                                                    name="countriesOfOrigin"
                                                    value={countriesOfOrigin}
                                                    onChange={handleProductDataChange}
                                                    placeholder="Kraj pochodzenia (pole nr 3 na Świadectwie)"
                                                    invalid={validators.countriesOfOrigin}
                                                />
                                            ) : (
                                                <textarea
                                                    className="form-control my-0 w23 h6 rounded-3"
                                                    name="countriesOfOrigin"
                                                    value={countriesOfOrigin}
                                                    placeholder="Kraj pochodzenia (pole nr 3 na Świadectwie)"
                                                    invalid={validators.countriesOfOrigin}
                                                    disabled
                                                />
                                            )}
                                    </PreviousValue>
                                    <span className="ms-auto small">pole nr 3 na świadectwie</span>
                                </div>
                            )}
                        </div>
                        )}


                        <div className='d-flex flex-row'>
                            <h4>Produkty / Towary - ilość, waga, opis</h4>
                        </div>
                        <div className="d-flex flex-row mb-1">
                            <div className='w23'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Ilość łącznie</label>
                                    <HelpTooltip title={tooltips[0]} wide={true} />
                                    <ValidationInfo title="Maksymalna ilość znaków - 33" visible={validators.totalQuantity} />
                                </div>
                                <PreviousValue show={showPreviousData && (totalQuantity !== prevTotalQuantity)} previousValue={prevTotalQuantity}>
                                    <input
                                        type="text"
                                        className="form-control my-0 w23 h2 rounded-3"
                                        maxLength="33"
                                        name="totalQuantity"
                                        value={totalQuantity}
                                        onChange={handleProductDataChange}
                                        placeholder="Wpisz łączną ilość (np. 13 szt./pcs.)"
                                        invalid={validators.totalQuantity}
                                    />
                                </PreviousValue>
                            </div>

                            <div className='ms-1 w11'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Łączna waga netto</label>
                                    <HelpTooltip title={tooltips[1]} />
                                    <ValidationInfo title="Maksymalna ilość znaków - 22" visible={validators.totalNetWeight} />
                                </div>
                                <PreviousValue show={showPreviousData && (totalNetWeight !== prevTotalNetWeight)} previousValue={prevTotalNetWeight}>
                                    <input
                                        type="text"
                                        className="form-control my-0 w11 h2 rounded-3"
                                        maxLength="22"
                                        name="totalNetWeight"
                                        value={totalNetWeight}
                                        onChange={handleProductDataChange}
                                        placeholder="Wpisz łączną wagę (np. 7 t)"
                                        invalid={validators.totalNetWeight}
                                    />
                                </PreviousValue>
                            </div>

                            <div className='ms-1 w11'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Łączna waga brutto</label>
                                    <HelpTooltip title={tooltips[2]} />
                                    <ValidationInfo title="Maksymalna ilość znaków - 20" visible={validators.totalGrossWeight} />
                                </div>
                                <PreviousValue show={showPreviousData && (totalGrossWeight !== prevTotalGrossWeight)} previousValue={prevTotalGrossWeight}>
                                    <input
                                        type="text"
                                        className="form-control my-0 w11 h2 rounded-3"
                                        maxLength="20"
                                        name="totalGrossWeight"
                                        value={totalGrossWeight}
                                        onChange={handleProductDataChange}
                                        placeholder="Wpisz łączną wagę (np. 7 t)"
                                        invalid={validators.totalGrossWeight}
                                    />
                                </PreviousValue>
                            </div>
                        </div>

                        <div className="d-flex flex-row pb-2 mb-1 border-bottom">
                            <div className='d-flex flex-column w23'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Szczegóły dotyczące transportu produktów / towarów</label>
                                    <ValidationInfo title="Maksymalna ilość znaków - 280, maksymalnie 7 wierszy" visible={validators.transportDetails} />
                                </div>
                                <PreviousValue show={showPreviousData && (transportDetails !== prevTransportDetails)} previousValue={prevTransportDetails}>
                                    <textarea
                                        className="form-control my-0 w23 h8 rounded-3"
                                        maxLength="286"
                                        name="transportDetails"
                                        value={transportDetails}
                                        onChange={handleProductDataChange}
                                        placeholder="Szczegóły dotyczące transportu produktów / towarów (pole nr 4 na Świadectwie)"
                                        invalid={validators.transportDetails}
                                    />
                                </PreviousValue>
                                <span className="ms-auto small">pole nr 4 na świadectwie</span>
                            </div>

                            <div className='d-flex flex-column ms-1 w23'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Uwagi dotyczące produktów / towarów</label>
                                    <ValidationInfo title="Maksymalna ilość znaków - 360, maksymalnie 9 wierszy" visible={validators.productsRemarks} />
                                </div>
                                <PreviousValue show={showPreviousData && (productsRemarks !== prevProductsRemarks)} previousValue={prevProductsRemarks}>
                                    <textarea
                                        className="form-control my-0 w23 h8 rounded-3"
                                        maxLength="368"
                                        name="productsRemarks"
                                        value={productsRemarks}
                                        onChange={handleProductDataChange}
                                        placeholder="Uwagi dotyczące produktów / towarów (pole nr 5 na Świadectwie)"
                                        invalid={validators.productsRemarks}
                                    />
                                </PreviousValue>
                                <span className="ms-auto small">pole nr 5 na świadectwie</span>
                            </div>
                        </div>

                        <h4>Produkty / Towary - podsumowanie</h4>
                        <div className="d-flex flex-row my-1">
                            <p>
                                Powyżej uzupełniłeś dane towarów i produktów objętych świadectwem. Wykorzystamy je w celu potwierdzenia ich pochodzenia.<br />
                                Na podstawie podanych danych, pola 6 i 7 na świadectwie będą wyglądały jak poniżej.<br />
                                Sprawdź czy wszystko się zgadza i niczego nie brakuje. Jeżeli musisz poprawić błędy - wróć do odpowiedniej sekcji powyżej.<br />
                                W uzasadnionych przypadkach (np. korzystanie z akredytywy) Twój partner handlowy może wymagać specyficznych zapisów na świadectwie. W&nbsp;takiej sytuacji możesz zaproponować ich treść edytując pola poniżej. Decyzję o akceptacji tych zapisów lub ich edycji podejmie operator analizujący Twój wniosek.
                            </p>
                        </div>

                        <div className="d-flex flex-row mb-1">
                            <PreviousValue show={showPreviousData && (prevEditSummaries !== editSummaries)} previousValue={["true", true].includes(prevEditSummaries) ? "Tak" : "Nie"}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="editSummaries"
                                        name="editSummaries"
                                        onChange={handleProductDataChange}
                                        checked={(editSummaries === true || editSummaries === "true")}
                                    />
                                    <label className="form-check-label" htmlFor="editSummaries">
                                        Chcę edytować widoczną na świadectwie listę produktów / towarów i ich ilości, wagę itp.
                                    </label>
                                </div>
                            </PreviousValue>
                        </div>

                        {(productsSummaryEdit !== null && quantitySummaryEdit !== null) && (
                            <div className="d-flex flex-row">
                                <div className='d-flex flex-column w35'>
                                    <PreviousValue show={showPreviousData && (productsSummaryEdit !== prevProductsSummaryEdit)} previousValue={prevProductsSummaryEdit}>
                                        {(editSummaries === false || editSummaries === "false") ? (
                                                <textarea
                                                    className="form-control my-0 h8 rounded-3"
                                                    name="productsSummaryEdit"
                                                    value={productsSummaryEdit}
                                                    onChange={handleProductDataChange}
                                                    placeholder="Lista produktów / towarów"
                                                    disabled
                                                />
                                        ) : (
                                            <textarea
                                                className="form-control my-0 h8 rounded-3"
                                                maxLength="3600"
                                                name="productsSummaryEdit"
                                                value={productsSummaryEdit}
                                                onChange={handleProductDataChange}
                                                placeholder="Lista produktów / towarów"
                                            />
                                        )}
                                    </PreviousValue>
                                    <span className="ms-auto small">pole nr 6 na świadectwie</span>
                                </div>

                                <div className='d-flex flex-column ms-1 w11'>
                                    <PreviousValue show={showPreviousData && (quantitySummaryEdit !== prevQuantitySummaryEdit)} previousValue={prevQuantitySummaryEdit}>
                                        {(editSummaries === false || editSummaries === "false") ? (
                                            <textarea
                                                className="form-control my-0 h8 rounded-3"
                                                name="quantitySummaryEdit"
                                                value={quantitySummaryEdit}
                                                onChange={handleProductDataChange}
                                                placeholder="Ilości"
                                                disabled
                                            />
                                        ) : (
                                            <textarea
                                                className="form-control my-0 h8 rounded-3"
                                                maxLength="1200"
                                                name="quantitySummaryEdit"
                                                value={quantitySummaryEdit}
                                                onChange={handleProductDataChange}
                                                placeholder="Ilości"
                                            />
                                        )}
                                    </PreviousValue>
                                    <span className="ms-auto small">pole nr 7 na świadectwie</span>
                                </div>
                            </div>
                        )}
                    </div>)}
        </div>
    );
}

export default ProductSummary;