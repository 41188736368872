import React, { useState, useEffect } from 'react';

function ProductMultiSelector({ selectedProducts, availableProducts, onSelect, onRemove, onRemoveAll, readOnly = "false", openAtStart = false }) {
    const [products, setProducts] = useState([]);
    const [selected, setSelected] = useState(selectedProducts || []);
    //const [connectedProducts, setConnectedProducts] = useState(selectedProducts.sort((a, b) => a.productName.localeCompare(b.productName)));
    const [filter, setFilter] = useState("");
    const [showSelector, setShowSelector] = useState(openAtStart);

    const selectAll = () => {
        setFilter("");
        products.forEach(product => {
            if (!selected.some((prod) => prod === product)) 
                onSelect(product);
        });
        setSelected(products);
        //onSelect(products, index);
    }

    const clearAll = () => {
        setFilter("");
        onRemoveAll();
        setSelected([]);
    }

    useEffect(() => {
        setSelected(selectedProducts || []);
        if (availableProducts) {
            setProducts([...new Set([...availableProducts || [], ...selectedProducts || []])].sort((a, b) => a.localeCompare(b)));
        }
    }, []);

    useEffect(() => {
        setSelected(selectedProducts || []);
        if (availableProducts) {
            setProducts([...new Set([...availableProducts || [], ...selectedProducts || []])].sort((a, b) => a.localeCompare(b)));
        }
    }, [availableProducts, selectedProducts]);

    if ([true, "true"].includes(readOnly))
        return null;

    if (!showSelector) {
        return (
            <div className='d-flex flex-row'>
                <button type="button" className="btn btn-outline-primary w9 h2" onClick={() => setShowSelector(true)}>
                    Wybierz produkty
                </button>
            </div>
        );
    }

    return (
        <div className='w-100'>
            <div className='d-flex flex-row'>
                <input type="text" className="form-control h2 w25 rounded-end-0" placeholder="Filtruj produkty" value={filter} onChange={(e) => setFilter(e.target.value)} />
                <button type="button" className="btn btn-outline-secondary border border-start-0 rounded-start-0 h2 tooltip text-dark" onClick={() => setFilter("")}>X<span className='tooltiptext'>Wyczyść filtr</span></button>
                <button type="button" className="btn btn-outline-primary w9 h2 ms-auto tooltip" onClick={() => selectAll()}>
                    Dodaj wszystkie
                    <span className="tooltiptext">Dodaje wszystkie produkty do procesu produkcji</span>
                </button>
                <button type="button" className="btn btn-outline-primary w9 h2 ms-1 tooltip" onClick={() => clearAll()}>
                    Usuń wszystkie
                    <span className="tooltiptext">Usuwa wszystkie produkty z procesu produkcji</span>
                </button>
            </div>
            {(((products.length > 0) && ([false, "false"].includes(readOnly))) && (
                products.filter((product) => product.toLowerCase().includes(filter.toLowerCase())).map((product, indx) => (
                    <div key={"product_" + indx} className='d-flex flex-row mt-1'>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={"product_" + indx}
                            value={product}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelected(prevProducts => {
                                        return [...prevProducts, product];
                                    });
                                    onSelect(product);
                                } else {
                                    setSelected(prevProducts => {
                                        return prevProducts.filter((prod) => prod !== product);
                                    });
                                    onRemove(product);
                                }

                            }}
                            checked={selected.some((prod) => prod === product)}
                        />
                        <label className="form-check-label" htmlFor={"product_" + indx}>{product}</label>
                    </div>
                ))
            ))
            }
            <button type="button" className="btn btn-outline-primary rounded-2 w9 h2 mt-1" onClick={() => setShowSelector(false)}>
                Zamknij
            </button>
        </div>
    );
}

export default ProductMultiSelector;
