import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail, validateLetters, validateLettersDigitsSpecChars } from '../../utils/Validators';
import { addUser } from '../../services/User';
import { getConsentText } from '../../services/Consent';
import LoadingSpinner from './LoadingSpinner';

function UserRegistration() {
	const navigate = useNavigate();

	const [email, setEmail] = useState();
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [consent1, setConsent1] = useState(false);
	const [consent2, setConsent2] = useState(false);
	const [consent3, setConsent3] = useState(false);
	const [consent2Text, setConsent2Text] = useState();
	const [consent3Text, setConsent3Text] = useState();

	const [loading, setLoading] = useState(false);
	const [valid, setValid] = useState(false);

	const handleInput = (e) => {
		const { name, value } = e.target;

		switch (name) {
			case "firstName":
				if (validateLetters(value, 40, false))
					setFirstName(value);
				break;
			case "lastName":
				if (validateLetters(value, 40, false))
					setLastName(value);
				break;
			case "email":
				if (validateLettersDigitsSpecChars(value, 250, false))
					setEmail(value);
				break;
			default:
		}
	}

	const handleUserRegister = async () => {
		let result = false;
		result = await addUser(email, firstName, lastName, true, consent1, consent2);
		if (result) {
			navigate("/Register/Success/" + btoa(email));
		}
		else
			navigate("/Register/Failure");
	}

	const validate = () => {
		setValid(validateLetters(firstName, 40, true) && validateLetters(lastName, 40, true) && validateEmail(email, true) && consent1);
	}

	useEffect(() => {
		validate();
	}, [firstName, lastName, email, consent1]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const cons2 = await getConsentText(1);
			setConsent2Text(cons2);
			const cons3 = await getConsentText(2);
			setConsent3Text(cons3);
			setLoading(false);
		}

		fetchData();
	}, []);

	return (
		<div className="row justify-content-center justify-content-md-start">
			<div className="col-12 col-md-8 col-lg-6 col-xl-5 my-2 w-100">
				<span className="d-flex flex-column align-items-center mb-2 w-40">
					<img src="/assets/images/kig-e7Ti-vgY.png" className="mx-auto" alt="..." />
					<span className="text-white" style={{ fontSize: '0.75rem' }}>KRAJOWA IZBA GOSPODARCZA</span>
				</span>
				<div className="d-flex flex-row bg-light rounded-4 w-100">
					<div className="py-2 px-4 px-xl-5 bg-white rounded-4 w-40">
						<h1 className="display-4 text-center mb-1">Rejestracja użytkownika</h1>
						<p className="text-muted text-center mb-4">Elektroniczne świadectwo pochodzenia</p>
						<div className='d-flex flex-column w-100'>
							<div className='mb-1'>
								<label className="form-label">Adres email *</label>
								<input
									type="text"
									className="form-control my-0 h2 rounded-3"
									name='email'
									value={email}
									onChange={handleInput}
									maxLength="250"
									placeholder="Adres email, np. jan.kowalski@email.com.pl (wypełnienie obowiązkowe)"
									required={true}
								/>
							</div>

							<div className='mb-1'>
								<label className="form-label">Imię *</label>
								<input
									type="text"
									className="form-control my-0 h2 rounded-3"
									name='firstName'
									value={firstName}
									onChange={handleInput}
									maxLength="40"
									placeholder="Imię (wypełnienie obowiązkowe)"
									required={true}
								/>
							</div>

							<div className='mb-2'>
								<label className="form-label">Nazwisko *</label>
								<input
									type="text"
									className="form-control my-0 h2 rounded-3"
									name='lastName'
									value={lastName}
									onChange={handleInput}
									maxLength="40"
									placeholder="Nazwisko (wypełnienie obowiązkowe)"
									required={true}
								/>
							</div>

							<div className='d-flex flex-row'>
								<button type="submit" className="btn btn-outline-primary w7 h2 rounded-2" onClick={() => navigate('/Login', { replace: true })}>
									Anuluj
								</button>

								{valid ? (
									<button type="submit" className="btn btn-primary w7 h2 rounded-2 ms-auto" onClick={handleUserRegister}>
										Zarejestruj
									</button>
								) : (
									<div className="btn btn-primary w7 h2 rounded-2 ms-auto text-white opacity-50 tooltip" disabled>
										Zarejestruj
										<span className="tooltiptext">Wypełnij wszystkie obowiązkowe pola i zaakceptuj Regulamin Serwisu</span>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className='w-60'>
						{loading ? (
							<div className='p-2'>
								<LoadingSpinner text='Pobieranie treści oświadczeń' />
							</div>
						) : (
							<div className='d-flex flex-column p-2 me-1 overflow-auto'>
								<span>
									Administratorem Pani/Pana danych osobowych jest Krajowa Izba Gospodarcza z siedzibą w Warszawie 00-074, ul. Trębacka 4,
									tel.: 22 6309 600, email: kig@kig.pl. Pełna treść klauzuli informacyjnej RODO <a href='#1'>tutaj</a>.
								</span>
								<div className='d-flex flex-row mt-1'>
									<input type="checkbox" className="form-check-input" id="consent1" checked={consent1} onChange={() => setConsent1(!consent1)} />
									<label className="form-check-label" htmlFor="consent1">Zapoznałem się z <a href='#2'>Regulaminem Serwisu</a> i akceptuję jego treść. *</label>
								</div>

								<div className='d-flex flex-row pt-1 mt-1 border-top border-primary-subtle'>
									<span>
										Jeśli chcą państwo otrzymywać informacje o inicjatywach realizowanych w ramach działalności statutowej
										Krajowej Izby Gospodarczej, wspierającej rozwój przedsiębiorczości, prosimy zaznaczyć obie poniższe zgody:
									</span>
								</div>
								<div className='d-flex flex-row mt-1'>
									<input type="checkbox" className="form-check-input" id="consent2" checked={consent2} onChange={() => setConsent2(!consent2)} />
									<label className="form-check-label" htmlFor="consent2" dangerouslySetInnerHTML={{ __html: consent2Text }}></label>
								</div>

								<div className='d-flex flex-row my-1'>
									<input type="checkbox" className="form-check-input" id="consent3" checked={consent3} onChange={() => setConsent3(!consent3)} />
									<label className="form-check-label" htmlFor="consent3" dangerouslySetInnerHTML={{ __html: consent3Text }}></label>
								</div>

								<span>
									Administratorem Pani/Pana danych osobowych jest Krajowa Izba Gospodarcza z siedzibą w Warszawie 00-074, ul. Trębacka 4,
									tel.: 22 6309 600, email: kig@kig.pl. Pełna treść klauzuli informacyjnej RODO <a href='#1'>tutaj</a>.
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserRegistration;