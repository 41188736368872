/**
 * Renderuje nagłówek komponentu karty.
 * @param {Object} props - Właściwości komponentu.
 * @param {string} props.text - Tekst do wyświetlenia w nagłówku.
 * @param {boolean} [props.onlyText=false] - Wskazuje, czy w nagłówku ma być wyświetlany tylko tekst.
 * @param {string} [props.commentId] - ID komentarza powiązanego z nagłówkiem.
 * @param {string} [props.commentLocation=null] - Lokalizacja komentarza powiązanego z nagłówkiem.
 * @param {boolean} [props.commentVisible=true] - Wskazuje, czy komentarz jest widoczny.
 * @param {Function} [props.registerCallback] - Funkcja zwrotna do rejestracji komponentu komunikacyjnego.
 * @param {boolean} [props.viewAll=false] - Wskazuje, czy ma być widoczna opcja "Zobacz wszystkie".
 * @returns {JSX.Element} Wyrenderowany komponent CardHeader.
 */
import CommunicationSpot from "./CommunicationSpot";

function CardHeader({ text, onlyText = false, commentId, commentLocation = null, commentVisible = true, registerCallback, viewAll = false }) {

    return (
        <div className='d-flex align-items-center ms-4 mt-4 mb-0 w55'>
            <h3 className="small-caps">{text.toUpperCase()}</h3>
            <div className='ms-auto'>
                {!onlyText && (
                    <CommunicationSpot 
                        commentId={commentId} 
                        location={commentLocation == null ? text : commentLocation} 
                        isVisible={commentVisible} 
                        registerCallback={registerCallback}
                        viewAll={viewAll}
                    />
                )}
            </div>
        </div>
    );
}

export default CardHeader;