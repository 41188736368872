import React, { useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';

function OperatorsPanel({ isVisible, modeChangeHandler }) {

    if (!isVisible)
        return null;

    return (
        <div className='d-flex flex-row'>
            <div className='ms-0 d-flex flex-row w23'>
                <div>
                    <div className='d-flex flex-column p-0 position-fixed'>
                        <div className='tooltip text-black sidepanel-nav rounded-0' onClick={() => modeChangeHandler(1)}>
                            <i className='bi-arrow-left' style={{ fontSize: "30px" }}></i>
                            <span className='tooltiptext tooltip-top-right tooltip-500'>Przejdź do widoku sprawy</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OperatorsPanel;
