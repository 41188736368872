import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ConfirmPopup = ({ show = false, title = "POTWIERDŹ", text = "Czy chcesz kontynuować?", confirmText = "OK", cancelText = "Anuluj", onConfirm, onCancel }) => {
    const [showState, setShow] = useState(show);

    useEffect(() => {
        setShow(show);
    }, [show]);

    const handleConfirm = () => {
        onConfirm();
        setShow(false);
    };

    const handleCancel = () => {
        onCancel();
        setShow(false);
    };
    
    if (showState) return (
        <>
        <div className="modal-backdrop show z-1"></div>
        <div className="modal fade show z-3" style={{ display: 'block' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header align-items-center">
                        <i className='bi bi-exclamation-triangle-fill text-warning me-1 fs-2' />
                        <h3 className='small-caps'>{title.toUpperCase()}</h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Zamknij" onClick={handleCancel}></button>
                    </div>
                    <div className="modal-body text-dark px-2 py-1">
                        <p>{text}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-primary w7" data-bs-dismiss="modal" onClick={handleCancel}>{cancelText}</button>
                        <button type="button" className="btn btn-primary w7 ms-1" data-bs-dismiss="modal" onClick={handleConfirm}>{confirmText}</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

ConfirmPopup.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

ConfirmPopup.defaultProps = {
    show: false,
    title: "POTWIERDŹ",
    text: "Czy chcesz kontynuować?",
};

export default ConfirmPopup;