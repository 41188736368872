import React, { useEffect, useState } from 'react';
import Sidebar from '../components/layout/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CertificatePreview from '../components/common/CertificatePreview';
import CardHeader from '../components/common/CardHeader';

export const CheckCertificateEmpty = () => {
    let navigate = useNavigate();

    React.useEffect(() => {
        navigate('/certificate/internalcheck/0/0');
    }, [navigate]);

    return null;
};

const showICCInfo = false

const CheckCertificate = ({ mode = "embedded" }) => {
    const [certId, setCertId] = useState('');
    const [certPIN, setCertPIN] = useState('');
    let navigate = useNavigate();
    const [certificate, setCertificate] = useState('');
    const [showHelp, setShowHelp] = useState(true);

    let { certificateId } = useParams();
    let { certificatePIN } = useParams();

    const handleIdTypeIn = (e) => {
        let value = e.target.value.replace(/[^0-9A-Za-z]/g, '');
        if (value.length > 12) {
            value = value.slice(0, 12);
        }
        const formattedValue = value.match(/.{1,4}/g)?.join('-') || '';
        setCertId(formattedValue);
    };

    const handlePINTypeIn = (e) => {
        let value = e.target.value.replace(/[^0-9a-zA-Z]/g, '');
        if (value.length > 6) {
            value = value.slice(0, 6);
        }
        setCertPIN(value);
    };

    const handleOk = async (e) => {
        e.preventDefault();
        setCertificate(certId);
        setCertPIN(certPIN);
        if (mode === "external")
            navigate('/certificate/check/' + certId + '/' + certPIN);
        else
            navigate('/certificate/internalcheck/' + certId + '/' + certPIN);
    };

    useEffect(() => {
        if (!!certificateId && certificateId === "0")
            setCertId('');
        else
            setCertId(certificateId);
        if (!!certificatePIN && certificatePIN !== "0")
            setCertPIN(certificatePIN);
    }, [certificateId, certificatePIN]);

    if (mode === "external")
        return (
            <main className="bg-secondary z-n1 background-image" style={{ height: '100vh', overflow: 'auto' }}>
                <div lang='en' className="container d-flex flex-column w47 z-1 py-2">
                    <div className="d-flex justify-content-center w47 card z-1 py-2 px-4 mb-3">
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column me-2 pe-2 border-end border-grey">
                                <div className='d-flex justify-content-center'>
                                    <img src="/assets/images/kig_grey.png" width={120} alt="Polish Chamber of Commerce" />
                                </div>
                                <div>
                                    <span className='fs-6 text-black text-opacity-50'>POLISH CHAMBER OF COMMERCE</span>
                                </div>
                            </div>

                            <div className='flex-column'>
                                <div>
                                    <h3>CERTIFICATE OF ORIGIN PREVIEW</h3>
                                </div>
                                <div lang="pl">Podgląd świadectwa pochodzenia</div>

                                <div className='mt-2'>
                                    Enter the certificate data to display its preview.
                                </div>

                                <div className='text-black text-opacity-50' lang="pl">
                                    Wprowadź dane świadectwa, aby wyświetlić jego podgląd.
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-column mt-2'>
                            {showHelp && (
                                <img src="/assets/images/stopka-co-pl.jpg" alt="Lokalizacja danych na świadectwie" />
                            )}
                            <div className="flex-column mt-1">
                                <form onSubmit={handleOk}>
                                    <div className="d-flex flex-wrap mb-2">
                                        <div className="d-flex flex-column w19">
                                            <label className="form-label">Certificate ID <span className='text-black text-opacity-50' lang="pl"> - Identyfikator świadectwa</span></label>
                                            <input type="text" className="form-control fs-4 fw-bold h2 rounded-3" maxLength="14" value={certId} onChange={(e) => handleIdTypeIn(e)} pattern='^[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}$' placeholder="ex. 96B0-2B56-C27F" required={true} />
                                        </div>
                                        <div className="d-flex flex-column w8 ms-1">
                                            <label className="form-label">PIN</label>
                                            <input type="text" className="form-control fs-4 fw-bold h2 rounded-3" maxLength="6" value={certPIN} onChange={(e) => handlePINTypeIn(e)} pattern='^[0-9a-zA-z]{6}$' placeholder="ex. 1b3af6" required={true} />
                                        </div>
                                        <div className="d-flex flex-column w7 ms-auto align-self-end">
                                            <button type="submit" className="btn btn-primary w7 h2 rounded-2 ms-auto" disabled={certId.replace(/-/g, '').length !== 12}>
                                                Ok
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {(certificateId !== '0') && (
                            <div className='d-flex flex-column mb-2'>
                                <CertificatePreview className="mw-100" applicationId={certificateId} pin={certificatePIN} mode="external" onSuccess={() => setShowHelp(false)} onFailure={() => setShowHelp(true)} />
                                {showICCInfo && (
                                    <div className='flex-column mt-2 pt-1 border-top'>
                                        <div className='tooltip fw-bold text-black fs-4 mb-1'>
                                            View the certificate on the International Chamber of Commerce website
                                            <span className="tooltiptext fw-normal fs-5" lang="pl">Podgląd świadectwa na stronie International Chamber of Commerce</span>
                                        </div>

                                        <div className='tooltip text-black'>
                                            You can also check this certificate in the global certificate database maintained by the International Chamber of Commerce. To do this, click the button below (you will be redirected to the ICC website).
                                            <span className="tooltiptext" lang="pl">Powyższe świadectwo możesz również sprawdzić w ogólnoświatowej bazie świadectw prowadzonej przez International Chamber of Commerce. W tym celu kliknij poniższy przycisk (zostaniesz przekierowany do strony ICC).</span>
                                        </div>

                                        <div className='d-flex flex-row mt-1'>
                                            <button className="btn tooltip btn-primary w11 h2 rounded-2 ms-auto text-white" onClick={() => alert("Redirecting to ICC website.")}>
                                                Check on ICC website
                                                <span className="tooltiptext" lang="pl">Przejście na stronę ICC</span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        <div>
                            <div className='d-flex flex-column'>
                                <div className='d-flex flex-row' lang='en'>
                                    The Polish Chamber of Commerce - Polish Chamber of Foreign Trade (PCC) has an official mandate from the Polish parliament to issue non-preferential certificates of origin.<br/>
                                    PCC is also accredited by the International Chamber of Commerce in Paris to issue these certificates.
                                    </div>
                                <div className='d-flex flex-row mt-1 text-black text-opacity-50' lang='pl'>
                                    Krajowa Izba Gospodarcza - Polska Izba Handlu Zagranicznego (KIG) jest ustawowo upoważniona do wystawiania niepreferencyjnych świadectw pochodzenia.
                                    KIG posiada również akredytację Międzynarodowej Izby w Paryżu do wystawiania tych świadectw.
                                </div>
                                <div className='d-flex flex-column mt-1' lang='en'>
                                    <span>For matters requiring clarification, please contact us: <a href="mailto:co@kig.pl">co@kig.pl</a></span>
                                    <span>Polish Chamber of Commerce, ul. Trębacka 4, 00-074 Warsaw, Poland</span>
                                </div>
                                <div className='d-flex flex-column mt-1 text-black text-opacity-50' lang='pl'>
                                    <span>W sprawach wymagających wyjaśnienia, prosimy o kontakt: <a href="mailto:co@kig.pl">co@kig.pl</a></span>
                                    <span>Krajowa Izba Gospodarcza, ul. Trębacka 4, 00-074 Warszawa, Polska</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );

    if (mode === "embedded")
        return (
            <main className="d-flex flex-nowrap" style={{ height: '100vh' }}>
                <div className="d-flex flex-column">
                    <Sidebar />
                </div>
                <div className="content">
                    <CardHeader text="Podgląd świadectwa pochodzenia" onlyText="true" />

                    <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                        <div className='d-flex flex-column'>
                            {(certificateId !== '0') ? (
                                <div>
                                    <span>Identyfikator świadectwa: <strong>{certificateId}</strong></span>
                                    <div style={{ maxHeight: '80%', overflowY: 'auto' }}>
                                        <CertificatePreview className="mw-100" applicationId={certificateId} pin={certificatePIN} />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <span>Wprowadź dane świadectwa, aby wyświetlić jego podgląd.</span>
                                    <img className='w-100' src="/assets/images/stopka-co-pl.jpg" alt="Lokalizacja danych na świadectwie" />
                                    <div className="mb-1">{certificate}</div>
                                    <div className="flex-column">
                                        <form onSubmit={handleOk}>
                                            <div className="d-flex flex-row flex-wrap">
                                                <div className="d-flex flex-column w19">
                                                    <label className="form-label">Identyfikator świadectwa</label>
                                                    <input type="text" className="form-control fs-4 h2" maxLength="14" value={certId} onChange={(e) => handleIdTypeIn(e)} pattern='^[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}$' placeholder="np. 96B0-2B56-C27F" required={true} />
                                                </div>
                                                <div className="d-flex flex-column w8 ms-1">
                                                    <label className="form-label">PIN</label>
                                                    <input type="text" className="form-control fs-4 h2" maxLength="6" value={certPIN} onChange={(e) => handlePINTypeIn(e)} pattern='^[0-9a-zA-z]{6}$' placeholder="np. 1b3af6" required={true} />
                                                </div>
                                                <div className="d-flex flex-column w7 ms-2 me-auto align-self-end">
                                                    <button type="submit" className="btn btn-primary w7 h2 rounded-2" disabled={certId.replace(/-/g, '').length !== 12}>
                                                        Ok
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        );
};

export default CheckCertificate;
