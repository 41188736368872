import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { ApiHost } from '../../utils/ApiConfig';
import ConfirmPopup from './ConfirmPopup';

const DataTable = ({ data, itemsPerPage, columnHeaders, dataColumns, initialSortColumn, actionNames, actionHandlers }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [tableData, setTableData] = useState();
	const [sortColumn, setSortColumn] = useState(initialSortColumn || 'Firma');
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortedData, setSortedData] = useState([]);
	let navigate = useNavigate();
	const { accessToken } = useAuth();
	const { userId } = useAuth();
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);
	const [businessId, setBusinessId] = useState();

	const unlinkBusiness = async (businessId) => {
		//e.preventDefault();
		try {
			const response = await axios.post(ApiHost + `/business/unlink`, {
				user_id: userId,
				business_id: businessId
			}, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			});
		} catch (error) {
			console.error("Błąd usuwania powiązania z firmą: ", error);
		}

	}

	// Funkcja do zmiany bieżącej strony
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// Funkcja do sortowania danych
	const handleSort = (column) => {
		if (sortColumn === column) {
			// Odwrócenie kolejności sortowania
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		} else {
			// Ustawienie nowej kolumny do sortowania
			setSortColumn(column);
			setSortOrder('asc');
		}
	};

	// Obliczanie numeru pierwszego i ostatniego wiersza na bieżącej stronie
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

	// Efekt sortowania danych przy zmianie sortColumn lub sortOrder
	useEffect(() => {
		if (!tableData)
			return;
		if (sortColumn) {
			const sorted = [...tableData].sort((a, b) => {
				if (a[sortColumn] < b[sortColumn]) {
					return sortOrder === 'asc' ? -1 : 1;
				}
				if (a[sortColumn] > b[sortColumn]) {
					return sortOrder === 'asc' ? 1 : -1;
				}
				return 0;
			});
			setSortedData(sorted);
		} else {
			setSortedData(tableData);
		}
	}, [sortColumn, sortOrder, tableData]);

	useEffect(() => {
		const prepareData = () => {
			let preparedData = [];

			if (dataColumns) {
				data.forEach(row => {
					let preparedRow = {};
					dataColumns.forEach((column, index) => {
						preparedRow = { ...preparedRow, [columnHeaders[index]]: row[column] };
					});
					if (row.Operations)
						preparedRow = { ...preparedRow, Operations: row.Operations };
					preparedData.push(preparedRow);
				}
				);
				data = preparedData;
			}
		}

		prepareData();
		setTableData(data);
	}, [data]);

	return (
		<div>
			<table className="table">
				<thead>
					<tr>
						{/* Renderowanie nagłówków kolumn z obsługą sortowania */}
						{columnHeaders.map((header, index) => (
							<th key={index} onClick={() => handleSort(header)} style={{ cursor: 'pointer' }}>
								{header}
								{sortColumn === header && (
									sortOrder === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
								)}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{/* Renderowanie wierszy danych */}
					{currentItems.map((row, rowIndex) => (
						<tr key={rowIndex}>
							{/* Renderowanie komórek w kolumnach */}
							{Object.keys(row).map((key, columnIndex) => (key !== "Operations" &&
								<td key={columnIndex}>
									{columnHeaders[columnIndex] === "Operacje" ? (
										<div>
											{actionNames && actionNames.map((actionName, actionIndex) => (
												<div className='d-inline-block'>
													{row.Operations[actionIndex] === 2 && (
														<button className={"btn btn-outline-primary " + (actionIndex > 0 ? "ms-1" : "")} onClick={() => actionHandlers[actionIndex](row[key])}>{actionName}</button>)}
													{row.Operations[actionIndex] === 1 && (
														<button className="btn btn-outline-primary" onClick={() => actionHandlers[actionIndex](row[key])} disabled>{actionName}</button>)}
												</div>
											))}
											{!actionNames && (
												<div className='d-inline-block'>
													<button className="btn btn-outline-primary w5 h2 tooltip" onClick={() => { setBusinessId(row[key]); setShowConfirmPopup(true); }}>
														Usuń
														<span className="tooltiptext">Usuń powiązanie z firmą</span>
													</button>
													{/* <button className="btn btn-outline-primary ms-1" onClick={() => startApplication(row[key])}>Wniosek</button> */}
												</div>
											)
											}
										</div>
									) : (
										<div>
											{(row[key] === true || row[key] === false) ? (
												<span className={'d-flex justify-content-center fs-4 ' + (row[key] ? 'bi-check-circle-fill text-success' : 'bi-circle text-danger')} title={row[key] ? 'Aktywny' : 'Zablokowany'}></span>
											) : (
												row[key]
											)
											}
										</div>
									)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			<ConfirmPopup
				show={showConfirmPopup}
				onCancel={() => setShowConfirmPopup(false)}
				onConfirm={() => { setShowConfirmPopup(false); unlinkBusiness(businessId); }}
				title="Usuwanie powiązania z firmą"
				text="Czy na pewno chcesz usunąć powiązanie z firmą?"
				confirmText="Tak"
				cancelText="Nie"
			/>
			{/* Renderowanie paginacji */}
			<nav>
				<ul className="pagination">
					{/* Tworzenie przycisków do zmiany strony */}
					{Array.from({ length: Math.ceil(sortedData.length / itemsPerPage) }, (_, i) => i + 1).map(pageNumber => (
						<li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
							<button onClick={() => paginate(pageNumber)} className="page-link">{pageNumber}</button>
						</li>
					))}
				</ul>
			</nav>
		</div>
	);
};

export default DataTable;
