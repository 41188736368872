import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { createEmptyApplication, getApplicationContent, updateApplicationContent, createEmptyProcessData, createEmptyStep3, getApplicationMetadata, getPreviousVersionContent, generateGUID } from '../../services/Application';
import ProductionProcess from './ProductionProcess';
import CountrySelector from './CountrySelector';
import ValidationInfo from './ValidationInfo';
import { validateCountryCode, validateLettersDigitsSpecChars, validateStep3 } from '../../utils/Validators';
import InfoPopup from './InfoPopup';
import { clientCanEdit, operatorCanEdit } from './ReadOnly';
import ReadonlyOverlay from './ReadonlyOverlay';
import CardHeader from './CardHeader';
import PreviousValue from './PreviousValue';
import LoadingSpinner from './LoadingSpinner';

const ApplicationStep3 = ({ registerCallback, warningsCallback }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();

    const [application, setApplication] = useState(createEmptyApplication());
    const [step2, setStep2] = useState(application.Step_2.Data);
    const [step3, setStep3] = useState(application.Step_3.Data);
    const [prevStep3, setPrevStep3] = useState(null);
    const [receiverUnknown, setReceiverUnknown] = useState(false);
    const [unknownReceiverDescription, setUnknownReceiverDescription] = useState("");

    const [showAlert, setShowAlert] = useState(false);

    const [formValid, setFormValid] = useState(true);
    const [products, setProducts] = useState([]);
    const [removedProcesses, setRemovedProcesses] = useState([]);
    const [addProcessVisible, setAddProcessVisible] = useState(true);
    const [validateTrigger, setValidateTrigger] = useState(0);
    const [validators, setValidators] = useState({ receiverName: "", receiverAddress: "", receiverCountry: "", unknownReceiverDescription: "" });
    const [infoPopupTrigger, setInfoPopupTrigger] = useState(0);

    const [status, setStatus] = useState(null);
    const [readOnly, setReadOnly] = useState(false);
    const [operatorMode, setOperatorMode] = useState(false);

    const [warningPopupTrigger, setWarningPopupTrigger] = useState(0);
    const [errorDescription, setErrorDescription] = useState({Errors: [], Disclaimer: ""});
    const [allowForwardOnError, setAllowForwardOnError] = useState(false);

    const [loading, setLoading] = useState(true);

    const debug = false;

    const handleForwardNavigation = () => {
        navigate('/application/edit/' + (parseInt(step) + 1) + '/' + id, { replace: true });
    }

    const validateData = async (application, e) => {
        const errorDescription = {Errors: [], Disclaimer: ""}

        let validationInfo = validateStep3(application.Step_3.Data, application.Step_2.Data);

        if (validationInfo.length > 0)
        {
            errorDescription.Errors.push({ Header: `Na bieżącej stronie wniosku stwierdzono następujące błędy (${validationInfo.length}):`, Description: validationInfo });
            errorDescription.Disclaimer = "Dane wprowadzone we wniosku wymagają poprawy. Możesz kontynuować wypełnianie wniosku, jednak przed jego wysłaniem konieczna będzie korekta.";
            setWarningPopupTrigger(warningPopupTrigger + 1);
        }
        setErrorDescription(errorDescription);

        warningsCallback(2, validationInfo.length);
        if (e.target.name !== "forward")
            setAllowForwardOnError(false);
        if ((validationInfo.length === 0) && (e.target.name === "forward"))
            handleForwardNavigation();
    };

    const handleSave = async (e) => {
        let app = application;
        let stepData = createEmptyStep3();
        if ([true, "true"].includes(receiverUnknown)) {
            stepData.Data.receiverAddress = "";;
            stepData.Data.receiverCountry = step3.receiverCountry;
            stepData.Data.receiverName = "";
            stepData.Data.receiverUnknown = true;
            stepData.Data.unknownReceiverDescription = unknownReceiverDescription;
        }
        else {
            stepData.Data.receiverAddress = step3.receiverAddress;
            stepData.Data.receiverCountry = step3.receiverCountry;
            stepData.Data.receiverName = step3.receiverName;
            stepData.Data.receiverUnknown = false;
            stepData.Data.unknownReceiverDescription = "";
        }

        //uzupełnienie brakujących identyfikatorów w procesach
        step3.Processes.forEach((process, index) => {
            if (process.id === "" || process.id === undefined){
                process.id = generateGUID();
            }
            process.Products.forEach(product => {
                if (!product.id)
                    product.id = generateGUID();
            });
        });

        if (Array.isArray(step3.Processes) && Array.isArray(removedProcesses)) {
            stepData.Data.Processes = step3.Processes.filter((_, index) => !removedProcesses.includes(index));
        } else {
            stepData.Data.Processes = step3.Processes || [];
            console.warn("Processes or removedProcesses are not arrays");
        }

        app.Step_3.Data = stepData.Data;
        if (debug) console.log(JSON.stringify(app.Step_3.Data));
        try {
            updateApplicationContent(id, app, userId, accessToken);
        } catch (error) {
            console.error("Failed to update application content", error);
        }

        await validateData(app, e);

        setInfoPopupTrigger(infoPopupTrigger + 1);
    };

    const handleForward = async (e) => {
        e.preventDefault();
        setAllowForwardOnError(true);
        handleSave(e);
    };

    const handleBack = async (e) => {
        e.preventDefault();
        navigate('/application/edit/' + (parseInt(step) - 1) + '/' + id, { replace: true });
    };


    function cleanValidators() {
        setFormValid(true);
    }

    const handleValidate = () => {
        cleanValidators();
    };

    const handleAddProcess = () => {
        reloadProducts();
        setStep3(pStep3 => {
            return {
                ...pStep3,
                Processes: [...pStep3.Processes, createEmptyProcessData()]
            };
        });
        if (debug) console.log("S3 hAP: " + step3.Processes.length);
    };

    const handleRemoveProcess = (pId, pIndex) => {
        removeValidator("Process_" + pId);

        setStep3(pStep3 => {
            let updatedProcesses = [...pStep3.Processes];
            updatedProcesses = updatedProcesses.filter((process) => process.id !== pId);
            return {
                ...pStep3,
                Processes: updatedProcesses
            };
        });
        reloadProducts();
    };


    const reloadProducts = () => {
        if (debug) console.log("S3_rP")
        //setProducts(application.Step_2.Data.Products.filter(product => product.producer === "true"));
        let updatedProducts = application.Step_2.Data.Products.filter(product => product.producer === "true");
        step3.Processes.forEach(process => {
            process.Products.forEach(product => {
                updatedProducts = updatedProducts.filter(prod => prod.productName !== product.name);
            });
        });
        setProducts(updatedProducts);
        //setAddProcessVisible(updatedProducts.length > 0);
    };

    const handleStep3DataChange = (e) => {
        if (debug) console.log("S3DC: [" + e.target.name + "]: " + e.target.value);
        if (debug) console.log("Products: " + products.length);
        const name = e.target.name.split("_")[0];
        const index = e.target.index;

        /* Walidacja pól na dole */
        if (name === "receiverName")
            setValidators({ ...validators, receiverName: (validateLettersDigitsSpecChars(e.target.value, 160, !receiverUnknown) ? "false" : "true") });
        if (name === "receiverAddress")
            setValidators({ ...validators, receiverAddress: (validateLettersDigitsSpecChars(e.target.value, 160, true, 4) ? "false" : "true") });
        if (name === "unknownReceiverDescription") {
            setValidators({ ...validators, unknownReceiverDescription: (validateLettersDigitsSpecChars(e.target.value, 280, receiverUnknown, 7) ? "false" : "true") });
            setUnknownReceiverDescription(e.target.value);
        }
        /* ********************* */

        if (name === "Process") {
            setStep3(pStep3 => {
                const updatedProcesses = [...pStep3.Processes];
                updatedProcesses[index] = e.target.value;
                return {
                    ...pStep3,
                    Processes: updatedProcesses
                };
            });
        }
        else {
            setStep3(pStep3 => {
                return {
                    ...pStep3,
                    [e.target.name]: e.target.value
                };
            });
        }
        reloadProducts();
    };

    const setApplicationMode = async () => {
        const metadata = await getApplicationMetadata(id, accessToken);
        if (metadata) {
            setStatus(metadata.status);
        }

        setOperatorMode(checkPermission("Obsługa wniosków"));

        if (checkPermission("Obsługa wniosków")) {
            if (!operatorCanEdit(metadata.status))
                setReadOnly(true);
        }
        else {
            if (!clientCanEdit(metadata.status))
                setReadOnly(true);
        }
    }

    const findMatchingPreviousProcess = (process) => {
        if (!prevStep3 || !prevStep3.Processes) 
            return {};

        let prevProcess = prevStep3.Processes.find(prevProcess => prevProcess.id === process.id);
        if (prevProcess) {
            return prevProcess;
        }
        return {};
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (debug) console.log("Pobieranie danych");
                const response = await getApplicationContent(id, accessToken);
                const prevApplication = await getPreviousVersionContent(id, accessToken);
                if (debug) console.log("Step 3 Processes: " + JSON.stringify(response.Step_3.Data.Processes));
                if (response.Step_3) {
                    setApplication(response);
                    setStep3(response.Step_3.Data);
                    if (response.Step_3.Data.receiverUnknown)
                        setReceiverUnknown(response.Step_3.Data.receiverUnknown);
                    if (response.Step_3.Data.unknownReceiverDescription)
                        setUnknownReceiverDescription(response.Step_3.Data.unknownReceiverDescription);
                }

                if (response.Step_2) {
                    setProducts(response.Step_2.Data.Products.filter(product => product.producer === "true"));
                    setStep2(response.Step_2.Data);
                }

                if (prevApplication && prevApplication.Step_3) {
                    setPrevStep3(prevApplication.Step_3.Data);
                }

                setApplicationMode();

            } catch (error) {
                console.error('Failed to fetch application content:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (debug) console.log("Step 3 data: " + JSON.stringify(step3));
        if ((products.length > 0) && (step3.Processes.length === 0)) {
            handleAddProcess();
        }
    }, [products, step3]);

    const validate = (e) => {
        const { name, value } = e.target;
        let newValidators = { ...validators };
        switch (name) {

            case "receiverCountry":
                newValidators.producerCountry = !validateCountryCode(value);
            default:
                break;
        }
        setValidators(newValidators);
    };

    const setValidator = (name, validator) => {
        setValidators(prevValidators => {
            return {
                ...prevValidators,
                [name]: validator
            };
        });
    };

    const removeValidator = (name) => {
        setValidators(prevValidators => {
            const updatedValidators = { ...prevValidators };
            delete updatedValidators[name];
            return updatedValidators;
        });
    };

    const countWarnings = () => {
        let count = 0;
        for (const key in validators) {
            if (validators.hasOwnProperty(key) && ((validators[key] === "true") || validators[key] === true)) {
                count++;
            }
        }
        return count;
    }

    useEffect(() => {
        warningsCallback(2, countWarnings());
    }, [validators]);

    useEffect(() => {
        if (debug) console.log("Products list change: " + products.length);
        let updatedProducts = application.Step_2.Data.Products.filter(product => product.producer === "true");
        step3.Processes.forEach(process => {
            process.Products.forEach(product => {
                updatedProducts = updatedProducts.filter(prod => prod.productName !== product.name);
            });
        });
        setAddProcessVisible(updatedProducts.length > 0);
    }, [step3.Processes]);

    useEffect(() => {
        if (debug) console.log("Zmiana widoczności dodawania procesu - " + addProcessVisible);
    }, [addProcessVisible]);

    useEffect(() => {
        if ([true, "true"].includes(receiverUnknown)) {
            handleStep3DataChange({ target: { name: "unknownReceiverDescription", value: "TO ORDER" } });
        }
    }, [receiverUnknown]);

    if (loading) {
        return <LoadingSpinner className='ms-4 mt-4' />;
    }

    return (
        <div className='pb-2'>
            <div>
                <CardHeader
                    text="Proces produkcji"
                    onlyText={true}
                />
                {(application.Step_1.Data.exporterIsProducer === "true") && (
                    <div className='ms-4 w55'>
                        <div>
                            {(step3.Processes.length === 0) && (addProcessVisible) && (
                                <h4>Dodawanie procesu produkcji</h4>
                            )}
                            W przypadku, gdy jesteś producentem eksportowanych produktów, potrzebujemy szczegółowych informacji, aby ustalić ich pochodzenie.<br />
                            Dodaj proces produkcji i uzupełnij pola w odniesieniu do każdego z produktów.
                        </div>
                        {(step3.Processes.length === 0) && (addProcessVisible) && (
                            ((application.Step_1.Data.exporterIsProducer === "true") && (addProcessVisible) &&
                                <div className='d-flex flex-row me-4 mt-2 w55'>
                                    <button className="btn btn-primary rounded-2 ms-auto w11" onClick={handleAddProcess} >
                                        Dodaj proces produkcji
                                    </button>
                                </div>
                            ))}
                    </div>
                )}
            </div>

            {((application.Step_1.Data.exporterIsProducer === "true") &&
                <div className='d-flex flex-column mt-2'>
                    {step3.Processes.length > 0 && step3.Processes.map((process, index) => (
                        <ProductionProcess
                            index={index}
                            key={"ProductionProcess_" + process.id}
                            data={process}
                            previousData={findMatchingPreviousProcess(process)}
                            showPreviousData={operatorMode}
                            name={"Process_" + index}
                            productsList={products}
                            onDataChange={handleStep3DataChange}
                            onRemove={handleRemoveProcess}
                            commentVisible={status !== 1}
                            registerCallback={registerCallback}
                            viewAll={checkPermission("Obsługa wniosków")}
                            handleValidate={setValidator}
                            readOnly={readOnly}
                        />
                    ))}
                </div>
            )}


            {((step3.Processes.length > 0) && (application.Step_1.Data.exporterIsProducer === "true") && (addProcessVisible) && (!readOnly)) && (
                <div className='d-flex flex-row ms-4 w55'>
                    <button
                        className="btn btn-primary w7 rounded-2 ms-auto w13 h2"
                        onClick={handleAddProcess}
                    >
                        Dodaj kolejny proces produkcji
                    </button>
                </div>
            )}
            <ReadonlyOverlay readonly={readOnly}>
                <CardHeader
                    text="Odbiorca"
                    commentId="3-2"
                    commentVisible={status !== 1}
                    registerCallback={registerCallback}
                    viewAll={checkPermission("Obsługa wniosków")}
                />
                <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                    <div className='d-flex flex-column w47'>
                        <div className='d-flex flex-row mb-1'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="receiverUnknown"
                                    id="receiverUnknown1"
                                    checked={!receiverUnknown}
                                    onChange={(e) => setReceiverUnknown(!e.target.checked)}
                                    disabled={readOnly}
                                />
                                <label className="form-check-label" htmlFor="receiverUnknown1">Znam dane odbiorcy</label>
                            </div>
                        </div>

                        {!receiverUnknown && (
                            <div>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex flex-row'>
                                        <div className='w23'>
                                            <div className='d-flex align-items-center'>
                                                <label className="form-label">Nazwa odbiorcy *</label>
                                                <ValidationInfo
                                                    title="Wypełnienie pola jest obowiązkowe. Długość nie może przekraczać 160 znaków."
                                                    visible={validators.receiverName === "true" ? "true" : "false"}
                                                />
                                            </div>
                                            <PreviousValue show={operatorMode && prevStep3 && (step3.receiverName !== prevStep3.receiverName)} previousValue={prevStep3 ? (prevStep3.receiverName ? prevStep3.receiverName : "") : ""}>
                                                <input
                                                    type="text"
                                                    className="form-control h2 w23 rounded-3"
                                                    name='receiverName'
                                                    maxLength="160"
                                                    value={step3.receiverName}
                                                    onChange={handleStep3DataChange}
                                                    placeholder="Nazwa obiorcy"
                                                    onBlur={handleStep3DataChange}
                                                    required={true}
                                                    invalid={validators.receiverName === "true" ? "true" : "false"}
                                                />
                                            </PreviousValue>
                                        </div>

                                        <div className='ps-1 w23'>
                                            <PreviousValue show={operatorMode && prevStep3 && (step3.receiverCountry !== prevStep3.receiverCountry)} previousValue={prevStep3 ? (prevStep3.receiverCountry ? prevStep3.receiverCountry : "") : ""}>
                                                <CountrySelector
                                                    label="Kraj odbiorcy"
                                                    data={step3.receiverCountry}
                                                    name="receiverCountry"
                                                    onDataChange={handleStep3DataChange}
                                                    required="true"
                                                    validateTrigger={validateTrigger}
                                                    onValidate={validate}
                                                />
                                            </PreviousValue>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!receiverUnknown && (
                            <div className='w23 mt-1'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label mb-0">Dane adresowe odbiorcy *</label>
                                    <ValidationInfo
                                        title="Wypełnienie pola jest obowiązkowe. Długość nie może przekraczać 160 znaków i 4 wierszy."
                                        visible={validators.receiverAddress === "true" ? "true" : "false"}
                                    />
                                </div>
                                <PreviousValue show={operatorMode && prevStep3 && (step3.receiverAddress !== prevStep3.receiverAddress)} previousValue={prevStep3 ? (prevStep3.receiverAddress ? prevStep3.receiverAddress : "") : ""}>
                                    <textarea
                                        className="form-control my-0 w23 h8 rounded-3"
                                        maxLength="164"
                                        name="receiverAddress"
                                        value={step3.receiverAddress}
                                        onChange={handleStep3DataChange}
                                        onBlur={handleStep3DataChange}
                                        placeholder="Dane adresowe odbiorcy"
                                        invalid={validators.receiverAddress === "true" ? "true" : "false"}
                                    />
                                </PreviousValue>
                            </div>
                        )}

                        <div className='d-flex flex-row mt-1'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="receiverUnknown"
                                    id="receiverUnknown2"
                                    checked={receiverUnknown}
                                    onChange={(e) => setReceiverUnknown(e.target.checked)}
                                    disabled={readOnly}
                                />
                                <label className="form-check-label" htmlFor="receiverUnknown2">Nie znam danych odbiorcy</label>
                            </div>
                        </div>

                        {receiverUnknown && (
                            <div className='d-flex flex-row mt-1'>
                                <div className='d-flex flex-column w23'>
                                    <div className='d-flex align-items-center'>
                                        <label className="form-label">Zawartość pola nr 2 na świadectwie *</label>
                                        <ValidationInfo
                                            title="Wypełnienie pola jest obowiązkowe. Długość nie może przekraczać 280 znaków i 7 wierszy."
                                            visible={validators.unknownReceiverDescription === "true" ? "true" : "false"}
                                        />
                                    </div>
                                    <PreviousValue show={operatorMode && prevStep3 && (step3.unknownReceiverDescription !== prevStep3.unknownReceiverDescription)} previousValue={prevStep3 ? (prevStep3.unknownReceiverDescription ? prevStep3.unknownReceiverDescription : "") : ""}>
                                        <textarea
                                            className="form-control my-0 w23 h8 rounded-3"
                                            maxLength="287"
                                            name="unknownReceiverDescription"
                                            value={unknownReceiverDescription}
                                            onChange={handleStep3DataChange}
                                            onBlur={handleStep3DataChange}
                                            placeholder="Wprowadź docelową zawartość pola nr 2 na świadectwie"
                                            invalid={validators.unknownReceiverDescription === "true" ? "true" : "false"}
                                        />
                                        <div className='d-flex flex-row'>
                                        <span className="ms-auto small">pole nr 2 na świadectwie</span>
                                        </div>
                                    </PreviousValue>
                                </div>

                                <div className='ps-1 w23'>
                                    <PreviousValue show={operatorMode && prevStep3 && (step3.receiverCountry !== prevStep3.receiverCountry)} previousValue={prevStep3 ? (prevStep3.receiverCountry ? prevStep3.receiverCountry : "") : ""}>
                                        <CountrySelector
                                            label="Kraj odbiorcy"
                                            data={step3.receiverCountry}
                                            name="receiverCountry"
                                            onDataChange={handleStep3DataChange}
                                            required="true"
                                            validateTrigger={validateTrigger}
                                            onValidate={validate}
                                        />
                                    </PreviousValue>
                                </div>
                            </div>
                            )}

                    </div>
                </div>

                {!readOnly && (
                    <div className="flex-column mt-3">
                        <form>
                            <div className="d-flex flex-row ms-4 w55">
                                <button
                                    className="btn btn-outline-primary me-auto w11 h2"
                                    onClick={handleBack}
                                >
                                    Wstecz
                                </button>

                                <button
                                    type="button"
                                    name="save"
                                    className="btn btn-outline-primary w11 h2"
                                    onClick={handleSave}
                                >
                                    Zapisz wersję roboczą
                                </button>

                                <button
                                    type="button"
                                    name="forward"
                                    className="btn btn-primary ms-1 w11 h2"
                                    onClick={handleForward}
                                    disabled={!formValid}
                                >
                                    Zapisz i przejdź dalej
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <InfoPopup
                    title={"Zapisano wersję roboczą wniosku."}
                    trigger={infoPopupTrigger}
                />
                <InfoPopup
                    title={""}
                    errorDescription={errorDescription}
                    trigger={warningPopupTrigger}
                    errorMode={true}
                    actionOnAccept={handleForwardNavigation}
                    allowForward={allowForwardOnError}
                />
            </ReadonlyOverlay>
        </div>
    );
};

export default ApplicationStep3;
