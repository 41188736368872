import React, { useEffect, useState } from 'react';
import { checkAccess } from '../../services/Application';
import { useAuth } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import AccessDenied from './AccessDenied';
import LoadingSpinner from './LoadingSpinner';


const AccessControl = ({ children }) => {
    const { id } = useParams();
    const { accessToken } = useAuth();
    const [accessGranted, setAccessGranted] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkUserAccess = async () => {
            setLoading(true);
            let hasAccess = false;
            if (id === "00000000-0000-0000-0000-000000000000")
                hasAccess = true;
            else
                hasAccess = await checkAccess(id, accessToken);
            setAccessGranted(hasAccess);
            setLoading(false);
        };

        checkUserAccess();
    }, []);

    if (loading) {
        return <div className='mx-4 my-3'><LoadingSpinner text='Sprawdzanie uprawnień' /></div>;
    }

    if (!accessGranted) {
        return <AccessDenied />;
    }

    return (
        <>
        {children}
        </>
    );
};

export default AccessControl;
