import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { ApiHost } from '../utils/ApiConfig';
import { getPermissions } from '../services/User';
import SessionWarning from '../components/common/SessionWarning';

const AuthContext = createContext();
const activityTimeout = 1800; // sekundy


export const useAuth = () => useContext(AuthContext);

const encryptData = (data, key) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
};

const decryptData = (ciphertext, key) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const AuthProvider = ({ children }) => {
	const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken') || '');
	const [isLoggedIn, setIsLoggedIn] = useState(!!accessToken);
	const [userName, setUserName] = useState(() => {
		const encryptedName = sessionStorage.getItem('userName');
		const refreshToken = sessionStorage.getItem('refreshToken');
		if (encryptedName && refreshToken) {
			return decryptData(encryptedName, refreshToken);
		}
		return '';
	});
	const [userId, setUserId] = useState(() => {
		const encryptedId = sessionStorage.getItem('userId');
		const refreshToken = sessionStorage.getItem('refreshToken');
		if (encryptedId && refreshToken) {
			return decryptData(encryptedId, refreshToken);
		}
		return '';
	});
	const [chamberId, setChamberId] = useState(() => {
		const encryptedId = sessionStorage.getItem('chamberId');
		const refreshToken = sessionStorage.getItem('refreshToken');
		if (encryptedId && refreshToken) {
			return decryptData(encryptedId, refreshToken);
		}
		return '';
	});
	const [chamberName, setChamberName] = useState(() => {
		const encryptedName = sessionStorage.getItem('chamberName');
		const refreshToken = sessionStorage.getItem('refreshToken');
		if (encryptedName && refreshToken) {
			return decryptData(encryptedName, refreshToken);
		}
		return '';
	});
	const [userPermissions, setUserPermissions] = useState(() => {
		if (!isLoggedIn) return {};

		try {
			const encryptedPermissions = sessionStorage.getItem('userPermissions');
			const refreshToken = sessionStorage.getItem('refreshToken');
			if (encryptedPermissions && refreshToken) {
				return decryptData(encryptedPermissions, refreshToken);
			}
			return {};
		} catch (error) {
			console.error('Błąd podczas pobierania uprawnień użytkownika', error);
			return {};
		}
    });
	const [tokenExpires, setTokenExpires] = useState(false);

	const login_step1 = async (username) => {
		try {
			const response = await axios.post(ApiHost + '/login_step1', { username });
			if (response.status === 200)
				return true;
			return false;
		} catch (error) {
			console.error('Błąd logowania', error);
			return false;
		}
	};

	// Sprawdzanie czy zalogowany użytkownik posiada uprawnienie
	const checkPermission = (permissionName) => {
		if (!isLoggedIn) {
			console.error('Użytkownik nie jest zalogowany');
			return false;
		}
		const result = userPermissions.some(permission => {
			if (permission.Name === permissionName)
				return true;
			return false;
		});
		return result;
	};

	// Funkcja logowania
	const login = async (username, password) => {
		try {
			const response = await axios.post(ApiHost + '/login', { username, password });
			setAccessToken(response.data.access_token);  // Aktualizacja stanu accessToken
			setUserName(response.data.user_name);
			setUserId(response.data.user_id);
			setChamberId(response.data.chamber_id);
			setChamberName(response.data.chamber_name);
			setIsLoggedIn(true);
			sessionStorage.setItem('accessToken', response.data.access_token);
			sessionStorage.setItem('refreshToken', response.data.refresh_token);
			sessionStorage.setItem('userName', encryptData(response.data.user_name, response.data.refresh_token));
			sessionStorage.setItem('userId', encryptData(response.data.user_id, response.data.refresh_token));
			sessionStorage.setItem('chamberId', encryptData(response.data.chamber_id, response.data.refresh_token));
			sessionStorage.setItem('chamberName', encryptData(response.data.chamber_name, response.data.refresh_token));
			sessionStorage.setItem('lastActivity', Date.now());

			try {
				const permissions = await getPermissions(response.data.user_id, response.data.access_token);
				const encryptedPermissions = encryptData(permissions, response.data.refresh_token);
				sessionStorage.setItem('userPermissions', encryptedPermissions);
				setUserPermissions(permissions);
			} catch (e) {
				console.error("Błąd podczas pobierania uprawnień użytkownika [" + response.data.user_id + "]: " + e);
			}
			return true;
		} catch (error) {
			console.error('Błąd logowania', error);
			return false;
		}
	};

	// Funkcja wylogowania
	const logout = async () => {
		if (!!accessToken) {
			try {
				const response = await axios.post(ApiHost + '/logout', {}, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				});
				if (response.status !== 200) {
					console.error('Błąd podczas wylogowania', response);
					return null;
				}
			} catch (error) {
				console.error('Błąd podczas wylogowania', error);
			}
		} 
		
		setAccessToken('');  // Wyczyszczenie stanu accessToken
		setIsLoggedIn(false);
		sessionStorage.removeItem('accessToken');
		sessionStorage.removeItem('refreshToken');
		sessionStorage.removeItem('userName');
		sessionStorage.removeItem('userId');
		sessionStorage.removeItem('chamberId');
		sessionStorage.removeItem('chamberName');
		sessionStorage.removeItem('userPermissions');
		sessionStorage.removeItem('lastActivity');

	};

	// Funkcja odświeżania tokena
	const refresh = async () => {
		try {
			const response = await axios.post(ApiHost + '/refresh', {}, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem('refreshToken')}`
				}
			});
			if (response.status !== 200) {
				console.error('Błąd odświeżania tokena', response);
				return null;
			} else {
				setTokenExpires(false);
				setAccessToken(response.data.access_token);
				sessionStorage.setItem('accessToken', response.data.access_token);
				return response.data.access_token;
			}
		} catch (error) {
			console.error('Błąd odświeżania tokena', error);
		} 
	};

	const checkIfActivityTimeout = () => {
		let lastActivity = sessionStorage.getItem('lastActivity') || 0;
		return (Date.now() - lastActivity > (activityTimeout * 1000));
	};

	const autoRefreshToken = async () => {
		let lastActivity = sessionStorage.getItem('lastActivity') || 0;

		if (Date.now() - lastActivity > (activityTimeout * 1000)) {
			return false;
		}

		const newAccessToken = await refresh();
		if (newAccessToken) {
			setAccessToken(newAccessToken);
			sessionStorage.setItem('accessToken', newAccessToken);
			return true;
		}

		return false;
	}

	// Sprawdzenie czy token wygasa za mniej niż 90 sekund
	const isTokenExpiring = (token) => {
		if (!token) return true;
		const decodedToken = JSON.parse(atob(token.split('.')[1]));
		const currentTime = Date.now() / 1000;
		return ((decodedToken.exp - currentTime) < 90);
	};

	useEffect(() => {
		setIsLoggedIn(!!accessToken);

		const checkToken = async () => {
			if (checkIfActivityTimeout()) {
				setTokenExpires(true);
			} else {

				if (isTokenExpiring(accessToken)) {
					if (await autoRefreshToken()) return;
					setTokenExpires(true);
				}
			}
		};

		if (accessToken) {
			// Sprawdź ważność tokena co pół minuty
			const intervalId = setInterval(checkToken, 30 * 1000);
			return () => clearInterval(intervalId);
		} else {
			logout();
		}

	}, [accessToken]);

	const resetSessionTimeout = () => {
		if (!isLoggedIn) return;

		sessionStorage.setItem('lastActivity', Date.now());
    };

	useEffect(() => {
        const handleUserActivity = () => {
            resetSessionTimeout();
        };

		window.addEventListener('click', handleUserActivity);
		window.addEventListener('keydown', handleUserActivity);

		resetSessionTimeout();

		return () => {
			window.removeEventListener('click', handleUserActivity);
			window.removeEventListener('keydown', handleUserActivity);
		};
    }, []);

	// Pobieranie listy zgód użytkownika
	const getConsentIds = async () => {
		try {
			const response = await axios.post(ApiHost + '/users/consent/ids',
				{ user_id: userId },
				{
					headers: {
						Authorization: `Bearer ${accessToken}`  // Dołączenie tokena do żądania
					}
				});
			return response.data;
		} catch (error) {
			console.error('Błąd dostępu do chronionej metody', error);
		}
	};

	// Dostarczanie stanu i funkcji przez kontekst
	return (
		<AuthContext.Provider value={{ accessToken, setAccessToken, login_step1, login, logout, userId, userName, chamberId, chamberName, userPermissions, isLoggedIn, getConsentIds, checkPermission }}>
			<SessionWarning
				show={tokenExpires}
				onExtendSession={() => {refresh(); resetSessionTimeout();}}
				onTimeout={logout} 
				initialSeconds={60}
			/>
			{children}
		</AuthContext.Provider>
	);
};
