import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext'; // Zaimportuj hook useAuth
import { useNavigate } from 'react-router-dom';

function TFLogin() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [step, setStep] = useState(0);
	const [showAlert, setShowAlert] = useState(false);
	const { login_step1, login } = useAuth(); // Uzyskaj dostęp do funkcji login z kontekstu
	const navigate = useNavigate();

	useEffect(() => {
		if (step === 2) {
			const timeoutId = setTimeout(() => {
				navigate('/', { replace: true });
			}, 2000);
			return () => clearTimeout(timeoutId);
		}
	}, [step, navigate]);

	const onError = () => {
		setShowAlert(true);
		setStep(0);
	};

	const onSuccess = () => {
		setStep(2);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (step === 0 || step === 3) {
			setStep(1);
			await login_step1(username);
		} else {
			const dane = await login(username, password);
			if (dane) {
				onSuccess();
			} else {
				onError();
			}
		}
	};

	switch (step) {
		case 1:
			return (
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<label className="form-label">Adres e-mail</label>
						<input type="text" className="form-control mb-2 h2 rounded-3" value={username} disabled />
						<label className="form-label">Hasło jednorazowe (token)</label>
						<input type="text" className="form-control mb-2 h2 rounded-3" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="token" />
						<button type="submit" className="btn btn-primary h2 mb-2 rounded-3 w-100">Zaloguj</button>
					</div>
				</form>
			);

		case 2:
			return (
				<div className="alert alert-success fade show" role="alert">
					<div className='d-flex flex-row'>
						<div>
							<strong>Zalogowano pomyślnie</strong><br />
							Za chwilę nastąpi przekierowanie do systemu.
						</div>
						<div className="ms-auto spinner-border" role="status">
							<span className="visually-hidden">...</span>
						</div>
					</div>
				</div>
			);

		default:
			return (
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						{showAlert && (
							<div className="alert alert-warning alert-dismissible d-flex align-items-center fade show" role="alert">
								<div>
									<strong>Błąd logowania</strong><br />Sprawdź poprawność danych do logowania.
								</div>
								<button type="button" className="btn-close" onClick={() => setShowAlert(false)} aria-label="Close"></button>
							</div>
						)}
						<label className="form-label">Adres e-mail</label>
						<input type="email" className="form-control mb-2 h2 rounded-3" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="email@domena.pl" required />
						<button type="submit" className="btn btn-lg w-100 btn-primary mb-1">Dalej</button>
						<a href='/Register'>Zarejestruj się</a>
					</div>
				</form>
			);
	}
}

export default TFLogin;