import React, { useState, useEffect } from 'react';
import Sidebar from '../components/layout/Sidebar'
import { useNavigate } from 'react-router-dom';
import UserData from '../components/common/UserData';
import { useAuth } from '../context/AuthContext';
import AccessDenied from '../components/common/AccessDenied';
import CardHeader from '../components/common/CardHeader';
import { getUserConsentIds, getConsentDetails } from '../services/Consent';
import Consent from '../components/common/Consent';
import LoadingSpinner from '../components/common/LoadingSpinner';

function ProfileEdit() {
    const [infoPopupTrigger, setInfoPopupTrigger] = useState(0);
    let navigate = useNavigate();
    const { accessToken, userId } = useAuth();
    const [consentIds, setConsentIds] = useState([]);
    const [consents, setConsents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [gdprAccepted, setGdprAccepted] = useState(false);
    const [marketingAccepted, setMarketingAccepted] = useState(false);

    const handleBack = () => {
        navigate('/application/list', { replace: true });
    }

    const handleAfterSave = () => {
        handleBack();
        setInfoPopupTrigger(infoPopupTrigger + 1);
    }

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            const cIds = await getUserConsentIds(userId, accessToken);
            setConsentIds(cIds);
        
            let tempConsents = [];
            const consentPromises = cIds.map(async (consentId) => {
                const consent = await getConsentDetails(consentId.Id, accessToken);
                tempConsents.push(consent);
            });
        
            await Promise.all(consentPromises);
            setConsents(tempConsents);
        
            let gdpr = false;
            let marketing = false;
            tempConsents.forEach(tConsent => {
                if (tConsent.Type === 1)
                    gdpr = true;
                if (tConsent.Type === 2)
                    marketing = true;
            });
            setGdprAccepted(gdpr);
            setMarketingAccepted(marketing);
        };

        fetchData();
        setLoading(false);
    }, []);

    return (
        <main className="d-flex flex-row h-100 ">
            <Sidebar />
            <div className='w-100 overflow-auto'>
                <CardHeader text="Edycja profilu" onlyText="true" />
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                        <div className='d-flex flex-column'>
                            <div className='mb-2'>
                                W tym widoku możesz zarządzać swoimi danymi oraz wyrażonymi zgodami.
                            </div>
                            {userId && (
                                <UserData
                                    userId={userId}
                                    handleBack={handleBack}
                                    handleAfterSave={handleAfterSave}
                                    profileMode={true}
                                />
                            )}

                        </div>
                        {consentIds.length > 0 && (
                            <div className='mt-2'>
                                {consentIds.map(({ Id }, index) => (
                                    <Consent className={index > 0 ? "mt-2" : ""} key={Id} consentId={Id} />
                                ))}
                            </div>
                        )}

                        {!gdprAccepted && (
                            <div className='d-flex flex-row mt-2'>
                                <button className='btn btn-primary w13 h2' onClick={() => navigate('/account/consents/add/1', { replace: true })}>
                                    Dodaj zgodę handlową
                                </button>
                            </div>
                        )}

                        {!marketingAccepted && (
                            <div className='d-flex flex-row mt-2'>
                                <button className='btn btn-primary w13 h2' onClick={() => navigate('/account/consents/add/2', { replace: true })}>
                                    Dodaj zgodę informacyjną
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </main>
    );
}

export default ProfileEdit;
