import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/common/LoginForm';
import UserRegistration from '../components/common/UserRegistration';
import UserRegistrationResult from '../components/common/UserRegistrationResult';
import UserActivation from '../components/common/UserActivation';
import { useAuth } from '../context/AuthContext';
import { useParams } from 'react-router-dom';

function LoginPage({ mode = "login" }) {
	const navigate = useNavigate();
	const { logout } = useAuth();

	if (mode === "logout") {
		logout();
		navigate('/login');
	}
	const { e } = useParams();
	const encodedEmail = e ? e : null;
	const decodedEmail = encodedEmail ? atob(encodedEmail) : null;
	return (

		<div className="d-flex align-items-center bg-secondary relative" style={{ overflowX: 'hidden', minHeight: '100vh' }}>
			<img src="/assets/images/globe-5LTxm7b1.webp" className="img-cover d-none d-md-block" alt="..."></img>
			<div className="container relative">
				{mode === "login" && (
					<LoginForm />
				)}
				{mode === "register" && (
					<UserRegistration />
				)}
				{(mode === "register-success" || mode === "register-failure") && (
					<UserRegistrationResult success={mode === "register-success"} email={decodedEmail} />
				)}
				{mode === "activate" && (
					<UserActivation />
				)}
			</div>
		</div>
	);
}

export default LoginPage;
